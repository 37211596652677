import { Card, LabeledRadioInput } from "adviesbox-shared";
import { Formik, useFormikContext } from "formik";
import React, { useMemo, ReactElement, useContext, useEffect, useState } from "react";
import { AppDataContext } from "../navigation/appdata-context";
import { ValidatiePopup } from "./rapportage-validatie-popup";

type RapportageSelectProps = {
  setSelectedVoorstel: (selected: any) => void;
  selectedVoorstel: string;
  isDirty?: boolean;
};

type FormikValues = {
  selectedVoorstel: string;
};

const FormComponent = ({ voorstelkeuzeOpties, setVoorstel, isDirty }: any): any => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [showValidationPopup, setShowValidationPopup] = useState<boolean>(false);
  const [pendingValue, setPendingValue] = useState<string | null>(null);

  useEffect(() => {
    setVoorstel(values.selectedVoorstel);
  }, [voorstelkeuzeOpties, values, setVoorstel]);

  const handleChange = (newValue: string): void => {
    setPendingValue(newValue);
    if (isDirty) {
      setShowValidationPopup(true);
    } else {
      setFieldValue("selectedVoorstel", newValue);
    }
  };

  const handleCancel = (): void => {
    setShowValidationPopup(false);
    setPendingValue(null);
  };

  const handleConfirm = (): void => {
    if (pendingValue) {
      setVoorstel(pendingValue);
      setFieldValue("selectedVoorstel", pendingValue);
    }
    setShowValidationPopup(false);
    setPendingValue(null);
  };

  return (
    <>
      <div className="d-flex flex-wrap flex-row flex-grow-1">
        <div className="col-12 col-md-6 pt-1">
          <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
            {voorstelkeuzeOpties && (
              <LabeledRadioInput
                caption="Kies huidige situatie of voorstel"
                name={"selectedVoorstel"}
                options={voorstelkeuzeOpties}
                onChange={e => handleChange(e.target.value)}
                value={values.selectedVoorstel}
              />
            )}
          </Card>
        </div>
      </div>

      <ValidatiePopup
        infotekst="browserInfo"
        show={showValidationPopup}
        onHide={handleCancel}
        onSubmit={handleConfirm}
        navigatiepopup={true}
      />
    </>
  );
};

const RapportageSelect = ({ setSelectedVoorstel, selectedVoorstel, isDirty }: RapportageSelectProps): ReactElement => {
  const {
    menuInfo: { voorstellen }
  } = useContext(AppDataContext);

  // Voorstellen uit het menu
  const voorstelkeuzeOpties = useMemo(() => {
    const voorstelOpties =
      voorstellen?.map(voorstel => ({
        label: voorstel.naam || `Voorstel #${voorstel.volgnummer}`,
        value: voorstel.id
      })) || [];
    voorstelOpties.unshift({ label: "Huidige situatie", value: "Huidig" });
    return voorstelOpties;
  }, [voorstellen]);

  return (
    <Formik
      initialValues={{ selectedVoorstel: selectedVoorstel }}
      onSubmit={values => {
        setSelectedVoorstel(values.selectedVoorstel);
      }}
    >
      <FormComponent voorstelkeuzeOpties={voorstelkeuzeOpties} setVoorstel={setSelectedVoorstel} isDirty={isDirty} />
    </Formik>
  );
};

export default RapportageSelect;

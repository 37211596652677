import { LabeledRadioInput, SettingsType, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import classnames from "classnames";
import { FormikProps } from "formik";
import { User } from "oidc-client";
import React, { ReactElement, useState } from "react";
import * as Yup from "yup";
import AdviesBoxFormik from "../shared/utils/adviesbox-formik";
import Modal from "../shared/components/modal/Modal";
import classes from "../shared/components/dossier/dossier-balk.module.scss";
import { inbeheernemenModalApi } from "./inbeheernemen-modal-api";
import { InBeheerNemenType } from "./infra/inbeheernemen-types";
import { MenuInfoVoorstel } from "../.generated/forms/formstypes";

type InBeheerNemenModalModalProps = {
  settings: SettingsType;
  user: User | null;
  vestigingId: string;
  voorstellen: MenuInfoVoorstel[];
  onSubmit?: (data: InBeheerNemenType) => Promise<void>;
  closeModal?: () => void;
  bezigMetUitvoeren?: boolean;
};

const InBeheerNemenModal = ({
  data,
  settings,
  user,
  vestigingId,
  voorstellen,
  closeModal,
  bezigMetUitvoeren
}: { data: InBeheerNemenType } & InBeheerNemenModalModalProps): ReactElement => {
  const [busySubmitting, setBusySubmitting] = useState(bezigMetUitvoeren);
  const body = (
    <>
      <PlatformFoutenSamenvatting className={classes.platform_errors} />
      <FormFirstFocus>
        {busySubmitting && <div className={classnames("loader", classes.loader)} data-testid="loader" />}
        {
          <div className="px-2 pb-3 text-justify">
            Selecteer hieronder het voorstel die voor de klant de (nieuwe) huidige situatie is geworden. Er wordt een
            nieuw dossier aangemaakt waarin de gegevens uit het voorstel in de huidige situatie worden opgenomen.
          </div>
        }

        <LabeledRadioInput
          caption={"Voorstel"}
          name={"gekozenVoorstelId"}
          options={
            voorstellen?.map(c => ({
              label: c.naam || `Voorstel #${c.volgnummer}`,
              value: c.id
            })) || []
          }
        />
      </FormFirstFocus>
    </>
  );

  return (
    <AdviesBoxFormik<InBeheerNemenType>
      initialValues={{
        ...data,
        gekozenVoorstelId: voorstellen && voorstellen.length > 0 ? voorstellen[0].id : ""
      }}
      validationSchema={Yup.object()}
      onSave={closeModal}
      closeModal={closeModal}
      render={({ setFieldValue, submitForm, values }: FormikProps<InBeheerNemenType>): ReactElement => (
        <>
          <Modal
            title="Voorstel omzetten naar huidige situatie"
            body={body}
            onSubmitClick={async () => {
              if (busySubmitting === true) return;
              setBusySubmitting(true);
              const resultaat = await inbeheernemenModalApi(
                settings,
                user,
                vestigingId,
                values.gekozenVoorstelId,
                setFieldValue
              );
              const newAdviesdossierId = resultaat.id;
              /* istanbul ignore next */
              if (newAdviesdossierId) {
                const url = `${window.location.origin}/vestiging/${vestigingId}/adviesdossier/${newAdviesdossierId}/personalia`;
                window.open(url, "_blank");
                setBusySubmitting(false);
                await submitForm();
              }
            }}
            onCancelClick={closeModal}
            isSubmitting={!!busySubmitting}
            saveButtonTekst="Voorstel omzetten en openen"
          />
        </>
      )}
    />
  );
};

export default InBeheerNemenModal;

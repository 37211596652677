import {
  BerekenCurrencyButton,
  BerekenCurrencyInput,
  Icon,
  LabeledCurrencyInput,
  LabeledPercentageInput,
  ModalButton,
  LabeledResult,
  LoadingSpinner
} from "adviesbox-shared";
import classNames from "classnames";
import { connect, FormikContextType } from "formik";
import React, { ReactElement, useRef } from "react";
import { Financieringsoort, GebruikPandSoort } from "../../.generated/forms/formstypes";
import { bedragFormat, optellen } from "../../shared/utils/currency";
import AdvieskostenBemiddelingsvergoeding from "../financieringsopzet/advieskosten-bemiddelingsvergoeding";
import AfkoopErfpacht from "../financieringsopzet/afkoop-erfpacht";
import AndereFinanciering from "../financieringsopzet/andere-financiering";
import Boeterente from "../financieringsopzet/boeterente";
import Eigenwoningschuld from "../financieringsopzet/eigenwoningschuld";
import OverigeFinancieringskosten from "../financieringsopzet/overige-financieringskosten";
import VerbouwingVerbetering from "../financieringsopzet/verbouwing-verbetering";
import InbrengEigenGeldModal from "../inbreng-eigen-geld-modal/inbreng-eigen-geld-modal";
import { getTotaalFinancieringsbehoefte } from "../infra/financieringsbehoefte-berekenen-helper";
import { getFinancieringsbehoefteTextResources } from "../infra/financieringsbehoefte-resources";
import {
  FinancieringsbehoefteState,
  OverigeKostenInHypotheekModal as OverigeKostenInHypotheekModalType
} from "../infra/financieringsbehoefte-types";
import OverigeKostenInHypotheekModal from "../overige-kosten-in-hypotheek-modal/overige-kosten-in-hypotheek-modal";
import NationaleHypotheekGarantie from "../financieringsopzet/nationale-hypotheek-garantie";

type FinancieringsopzetOversluitenProps = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean | null>>;
  showModal: boolean | null;
  loading:boolean;
};

const Financieringsopzet = ({
  formik,
  formik: {
    values,
    setFieldValue,
    values: { financieringsopzet }
  },
  setShowModal,
  showModal,
  loading
}: FinancieringsopzetOversluitenProps & {
  formik: FormikContextType<FinancieringsbehoefteState>;
}): ReactElement => {
  const { inbrengEigenGeldModal, overigeKostenInHypotheekModal } = financieringsopzet;
  const soortFinancieringsBehoeftesMetLeveringsAkteEnOverdrachtsBelasting = useRef([Financieringsoort.Oversluiten]);

  return (
    <>
      {values.financiering.soortFinanciering !== Financieringsoort.Omzetting &&
        values.financieringsopzet.leningdelenWordenAfgelost && (
          <BerekenCurrencyInput
            caption="Af te lossen bestaande hypothe(e)k(en)"
            name={"financieringsopzet.afTeLossenBestaandeHypotheken"}
            disabled={financieringsopzet.afTeLossenBestaandeHypotheken.berekenen || false}
            tooltip={getFinancieringsbehoefteTextResources("TooltipAftelossenBestaandeHypotheken")}
          />
        )}

      {soortFinancieringsBehoeftesMetLeveringsAkteEnOverdrachtsBelasting.current.includes(
        values.financiering.soortFinanciering
      ) && <BerekenCurrencyInput caption="Overdrachtsbelasting" name="financieringsopzet.overdrachtsbelasting" />}

      {soortFinancieringsBehoeftesMetLeveringsAkteEnOverdrachtsBelasting.current.includes(
        values.financiering.soortFinanciering
      ) && <BerekenCurrencyInput caption="Leveringsakte" name="financieringsopzet.leveringsakte" />}

      <VerbouwingVerbetering state={"oversluiten"} />

      {values.erfpacht.erfpacht && <AfkoopErfpacht />}

      <LabeledCurrencyInput caption="Uitkoop partner" name="financieringsopzet.uitkoopPartner" />

      {values.financiering.soortFinanciering !== Financieringsoort.Omzetting && (
        <BerekenCurrencyInput
          caption="Hypotheekakte"
          name="financieringsopzet.hypotheekakte"
          tooltip={getFinancieringsbehoefteTextResources("TooltipHypotheekAkte")}
        />
      )}

      <LabeledCurrencyInput
        caption="Arbeidskosten notaris"
        tooltip={getFinancieringsbehoefteTextResources("TooltipArbeidskostenNotaris")}
        name="financieringsopzet.arbeidskostenNotaris"
      />

      <BerekenCurrencyInput
        caption="Taxatie"
        name="financieringsopzet.taxatie"
        tooltip={getFinancieringsbehoefteTextResources("TooltipTaxatie")}
      />
      {values.onderpand.gebruik === GebruikPandSoort.PrimaireWoning && (
        <NationaleHypotheekGarantie setShowModal={setShowModal} showModal={showModal} />
      )}

      <AdvieskostenBemiddelingsvergoeding />

      {values.financieringsopzet.leningdelenWordenAfgelost && <Boeterente />}

      {values.financiering.soortFinanciering !== Financieringsoort.Heropname && <OverigeFinancieringskosten />}

      {typeof values.financieringsopzet.afTeLossenoverigeLeningen === "number" &&
        values.financieringsopzet.afTeLossenoverigeLeningen > 0 && (
          <LabeledCurrencyInput
            caption="Af te lossen overige lening(en)"
            name="financieringsopzet.afTeLossenoverigeLeningen"
            readonly={true}
            tooltip={getFinancieringsbehoefteTextResources("TooltipAftelossenKredieten")}
          />
        )}

      {values.financiering.soortFinanciering === Financieringsoort.Omzetting && (
        <LabeledCurrencyInput caption="Kosten omzetting leningdeel" name="financieringsopzet.kostenOmzettingBedrag" />
      )}

      <LabeledResult
        caption="Overige kosten in hypotheek"
        name="overigeKostenInHypotheek"
        alignRight={true}
        currency={true}
        result={(): string =>
          bedragFormat(
            optellen([
              overigeKostenInHypotheekModal.lastenOverbruggingskrediet,
              overigeKostenInHypotheekModal.gewenstConsumptiefBedrag,
              overigeKostenInHypotheekModal.royementskostenBedrag,
              optellen(overigeKostenInHypotheekModal.overigeKostenSpecificaties.map((x): number | null => x.bedrag))
            ]),
            0,
            0,
            false
          )
        }
        appendChildren={
          <ModalButton
            resetSize={true}
            parent="financieringsopzet.overigeKostenInHypotheekModal"
            content={<Icon name="specificatie" alt="Overige kosten in hypotheek" />}
          >
            <OverigeKostenInHypotheekModal
              data={{
                ...values.financieringsopzet.overigeKostenInHypotheekModal,
                soortFinanciering: values.financiering.soortFinanciering
              }}
              onSave={(result: OverigeKostenInHypotheekModalType): void => {
                setFieldValue("financieringsopzet.overigeKostenInHypotheekModal", result);
              }}
            />
          </ModalButton>
        }
      />
      <LabeledResult
        name="financieringsopzet.totaleFinancieringsbehoefte"
        caption="Totale financieringsbehoefte"
        fontWeight="bold"
        alignRight={true}
        currency={true}
        result={(): string => bedragFormat(getTotaalFinancieringsbehoefte(values), 0, 0, false)}
      />

      <LabeledResult
        caption="Inbreng eigen geld"
        name="inbrengEigenGeldTotaal"
        alignRight={true}
        currency={true}
        result={(): string =>
          bedragFormat(
            optellen([
              inbrengEigenGeldModal.inbrengEigenGeld,
              inbrengEigenGeldModal.inbrengVoorVerlagenRestschuld,
              inbrengEigenGeldModal.eigenGeldOverwaarde.bedrag,
              inbrengEigenGeldModal.extraAflossing,
              inbrengEigenGeldModal.inbrengEigengeldBedrag1,
              inbrengEigenGeldModal.inbrengEigengeldBedrag2,
              inbrengEigenGeldModal.inbrengOpgebouwdeWaarde,
              inbrengEigenGeldModal.inbrengPolisBedrag
            ]),
            0,
            0,
            false
          )
        }
        appendChildren={
          <ModalButton
            resetSize={true}
            parent="financieringsopzet.inbrengEigenGeldModal"
            aria-label="Inbreng eigen geld specificatie"
            content={<Icon name="specificatie" alt="Inbreng eigen geld" />}
          >
            <InbrengEigenGeldModal
              data={values.financieringsopzet.inbrengEigenGeldModal}
              onSave={(result): void => {
                const values: FinancieringsbehoefteState = {
                  ...formik.values,
                  financieringsopzet: {
                    ...financieringsopzet,
                    inbrengEigenGeldModal: result
                  }
                };
                formik.setValues(values);
              }}
            />
          </ModalButton>
        }
      />

      <AndereFinanciering />

      <LabeledCurrencyInput
        caption="Gewenste hypotheek"
        name="financieringsopzet.gewensteHypotheek"
        disabled={
          financieringsopzet.gewensteHypotheekBerekenen ||
          values.financiering.soortFinanciering === Financieringsoort.Omzetting
        }
        fontWeight="bold"
        appendChildren={
          <BerekenCurrencyButton
            name="financieringsopzet.gewensteHypotheekBerekenen"
            berekenen={financieringsopzet.gewensteHypotheekBerekenen}
            hidden={values.financiering.soortFinanciering === Financieringsoort.Omzetting}
          />
        }
      />
      {values.onderpand.gebruik === GebruikPandSoort.PrimaireWoning && <Eigenwoningschuld />}

      <BerekenCurrencyInput caption="Hypothecaire inschrijving" name="financieringsopzet.hypothecaireInschrijving" />

      <LabeledPercentageInput
        additionalInputClass={classNames({
          "input-rood":
            values.financieringsopzet.bevoorschottingspercentage &&
            values.financieringsopzet.maxBevoorschottingspercentage &&
            values.financieringsopzet.bevoorschottingspercentage >
              values.financieringsopzet.maxBevoorschottingspercentage
        })}
        caption="Bevoorschottingspercentage"
        name="financieringsopzet.bevoorschottingspercentage"
        decimalen={2}
        readonly={true}
        appendChildren={
          <>{loading && <LoadingSpinner size="S" />}</>
        }
      />
    </>
  );
};

export default connect<FinancieringsopzetOversluitenProps, FinancieringsbehoefteState>(Financieringsopzet);

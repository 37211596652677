import { prefixWithZero, convertUtcToTimeZone } from "adviesbox-shared";
import {
  GeslachtOpties,
  Lening as AanvraagLeningDlEntry,
  BetaalwijzeAdvieskostenOptions,
  AanleidingGesprekOptions,
  RedenAankoopOptions,
  SoortOversluitingOptions,
  LeningMaatwerkreden,
  LeningOutput as AanvraagLeningDlOutput,
  PersoonBasis,
  LeningVoorstelReadOnly,
  Financieringsoort
} from "../../.generated/forms/formstypes";
import { mapAdresDlNaarUi } from "../../shared/generic-parts/adres/map-dl-2-ui";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { createMapEnum } from "../../shared/utils/create-map-enum";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { hasValue } from "../../shared/utils/helpers";
import {
  AanleidingGesprek,
  aanvraagLeningSchema,
  AanvraagLeningState,
  aanvullendeGegevensSchema,
  BetaalwijzeAdvieskosten,
  dataleverancierSchema,
  erfpachtSchema,
  financieringSchema,
  InventarisatieStudieleningSchema,
  leningSchema,
  maatwerkoplossingenEntrySchema,
  maatwerkoplossingSchema,
  notarisSchema,
  RedenAankoop,
  SoortFinanciering,
  SoortOversluiting,
  voorstellenSchema,
  voorstelSchema
} from "./aanvraag-lening-schema";

const mapBetaalwijzeAdvieskostenEnum = createMapEnum(BetaalwijzeAdvieskostenOptions).to({
  MeefinancierenInHypotheek: BetaalwijzeAdvieskosten.MeefinancierenInHypotheek,
  VoldoenUitEigenMiddelen: BetaalwijzeAdvieskosten.VoldoenUitEigenMiddelen
});

const mapRedenAankoop = createMapEnum(RedenAankoopOptions).to({
  WoningEigenGebruik: RedenAankoop.WoningEigenGebruik,
  TweedeWoning: RedenAankoop.TweedeWoning,
  DeelsVerhuurd: RedenAankoop.DeelsVerhuurd,
  GeheelVerhuurd: RedenAankoop.GeheelVerhuurd
});

const mapSoortOversluiting = createMapEnum(SoortOversluitingOptions).to({
  InterneOversluiting: SoortOversluiting.InterneOversluiting,
  OversluitingAndereGeldgever: SoortOversluiting.OversluitingAndereGeldgever
});

const mapAanleidingGesprek = createMapEnum(AanleidingGesprekOptions).to({
  Geen: AanleidingGesprek.Geen,
  Verbouwing: AanleidingGesprek.Verbouwing,
  AfkoopErfpacht: AanleidingGesprek.AfkoopErfpacht,
  ExtraAflossen: AanleidingGesprek.ExtraAflossen,
  Echtscheiding: AanleidingGesprek.Echtscheiding,
  EindeRentevastperiode: AanleidingGesprek.EindeRentevastperiode,
  AflossingsvormAanpassen: AanleidingGesprek.AflossingsvormAanpassen,
  MaandlastenVerlichting: AanleidingGesprek.MaandlastenVerlichting,
  Risicoscan: AanleidingGesprek.Risicoscan,
  Vermogensadvies: AanleidingGesprek.Vermogensadvies,
  Controlegesprek: AanleidingGesprek.Controlegesprek,
  FinancieringWoning: AanleidingGesprek.FinancieringWoning,
  Overig: AanleidingGesprek.Overig
});

const mapSoortFinanciering = createMapEnum(Financieringsoort).to({
  Geen: SoortFinanciering.Geen,
  AankoopBestaandeBouw: SoortFinanciering.AankoopBestaandeBouw,
  AankoopNieuwbouw: SoortFinanciering.AankoopNieuwbouw,
  Oversluiten: SoortFinanciering.Oversluiten,
  Heropname: SoortFinanciering.Heropname,
  _2eHypotheek: SoortFinanciering._2eHypotheek,
  _3eHypotheek: SoortFinanciering._3eHypotheek,
  _4eHypotheek: SoortFinanciering._4eHypotheek,
  Omzetting: SoortFinanciering.Omzetting,
  _1eHypotheek: SoortFinanciering._1eHypotheek
});

const aanvraagLeningDefaults = aanvraagLeningSchema.default();

const mapVoorstel = createMapToUi(voorstelSchema).from<LeningVoorstelReadOnly>({
  voorstelId: v => v.voorstelId,
  volgnummer: v => v.volgnummer,
  omschrijving: v => v.omschrijving,
  inschrijvingBedrag: v => v.inschrijvingBedrag,
  maatschappijCode: v => v.maatschappijCode,
  hdnOntvangerCode: v => v.hdnOntvangerCode,
  passeerdatum: v => v.passeerdatum,
  nhg: v => v.nhg,
  soortFinanciering: v => mapSoortFinanciering(v.soortFinanciering),
  erfpacht: v => v.erfpacht,
  heeftNieuwLeningdeel: v => v.heeftNieuwLeningdeel,
  isMeeneemHypotheek: v => v.isMeeneemHypotheek
});

const mapVoorstellen = createMapToUi(voorstellenSchema).from<AanvraagLeningDlEntry>({
  voorstellen: v => v.voorstellen?.map(voorstel => mapVoorstel(voorstel)).sort((a, b) => a.volgnummer - b.volgnummer)
});

const mapLening = createMapToUi(leningSchema).from<AanvraagLeningDlEntry>({
  bespreekzaak: v => v.algemeen?.bespreekzaak,
  verduurzamingBesproken: v => v.algemeen?.verduurzamingBesproken,
  betaalwijzeAdvieskosten: v =>
    hasValue(v.algemeen?.betaalwijzeAdvieskosten)
      ? mapBetaalwijzeAdvieskostenEnum(v.algemeen?.betaalwijzeAdvieskosten)
      : aanvraagLeningDefaults.lening.betaalwijzeAdvieskosten,
  gewensteHypotheek: v => v.algemeen?.gewensteHypotheekVoorstelId,
  inschrijvingsbedrag: v =>
    v.voorstellen?.find(voorstel => voorstel.voorstelId === v.algemeen?.gewensteHypotheekVoorstelId)
      ?.inschrijvingBedrag,
  nettoAdvieskosten: v => v.algemeen?.nettoAdvieskostenBedrag,
  soortAdvies: v => v.algemeen?.soortAdvies.toString().padStart(2, "0"),
  geselecteerdVoorstel: v => {
    /* istanbul ignore next */
    if (v.algemeen === null && v.voorstellen && v.voorstellen?.length > 0) {
      return mapVoorstel(v.voorstellen[0]);
    }
    return mapVoorstel(
      v.voorstellen?.find(voorstel => voorstel.voorstelId === v.algemeen?.gewensteHypotheekVoorstelId)
    );
  }
});

const mapMaatwerkoplossingenEntry = createMapToUi(maatwerkoplossingenEntrySchema).from<LeningMaatwerkreden>({
  betrekkingOpKlantId: v => v.betrekkingOpKlantId,
  maatwerkRedenId: v => v.maatwerkRedenId,
  reden: v => v.reden,
  volgnummer: v => v.volgnummer,
  toelichting: v => v.toelichting,
  seniorType: v => prefixWithZero(v.redentypeCode)
});

const mapMaatwerkoplossing = createMapToUi(maatwerkoplossingSchema).from<AanvraagLeningDlEntry>({
  maatwerkoplossing: v =>
    v.maatwerkoplossing?.maatwerkredenen ? v.maatwerkoplossing.maatwerkredenen.length > 0 : false,
  maatwerkredenen: v =>
    v.maatwerkoplossing?.maatwerkredenen
      ? v.maatwerkoplossing?.maatwerkredenen.map((v, i) => mapMaatwerkoplossingenEntry(v))
      : []
});

const mapPersoonsgegevens = createMapToUi(klantnaamSchema).from<PersoonBasis>({
  klantId: () => null,
  achternaam: v => v.achternaam,
  voorletters: v => v.voorletters,
  voornamen: v => v.voornamen,
  voorvoegsel: v => v.voorvoegsel,
  geboortedatum: v => (v.geboortedatum ? convertUtcToTimeZone(v.geboortedatum)?.toLocalDate() : null),
  roker: () => null,
  geslacht: v => v.geslacht as GeslachtOpties | null,
  aowdatum: v => null
});

const mapNotaris = createMapToUi(notarisSchema).from<AanvraagLeningDlEntry>({
  notariskantoorId: v =>
    !v.notaris?.gekozenNotariskantoorId || v.notaris?.gekozenNotariskantoorId.length === 0
      ? ""
      : v.notaris.gekozenNotariskantoorId,
  kantoornaam: v => v.notaris?.kantoornaam,
  typeNotaris: v => (v.notaris ? v.notaris.typeNotaris.toString() : "1"),
  adres: v => mapAdresDlNaarUi(v.notaris?.adres),
  persoonsgegevens: v => mapPersoonsgegevens(v.notaris?.contactpersoon),
  geslacht: v => (v.notaris?.contactpersoon?.geslacht ? v.notaris?.contactpersoon?.geslacht : null),
  telefoonnummer: v => v.notaris?.telefoonnummer
});

const mapFinanciering = createMapToUi(financieringSchema).from<AanvraagLeningDlEntry>({
  redenAanvraag: v => v.financiering?.redenAanvraag?.toString().padStart(2, "0"),
  redenAankoop: v => mapRedenAankoop(v.financiering?.redenAankoop),
  soortOversluiting: v => mapSoortOversluiting(v.financiering?.soortOversluiting),
  aanleidingGesprek: v => mapAanleidingGesprek(v.financiering?.aanleidingGesprek),
  verbouwingskosten: v => v.financiering?.verbouwingskosten
});

const mapDataleverancier = createMapToUi(dataleverancierSchema).from<AanvraagLeningDlEntry>({
  dataleveranciers: v =>
    v.dataLeverancier?.dataLeveranciers?.map(leverancier => {
      return {
        hdnCode: leverancier.hdnCode.toString().padStart(2, "0"),
        geselecteerd: leverancier.geselecteerd
      };
    })
});

const mapErfpacht = createMapToUi(erfpachtSchema).from<AanvraagLeningDlEntry>({
  ingangsdatum: v => (v.erfpacht?.ingangsdatum ? convertUtcToTimeZone(v.erfpacht?.ingangsdatum)?.toLocalDate() : null)
});

const mapAanvullendeGegevens = createMapToUi(aanvullendeGegevensSchema).from<AanvraagLeningDlEntry>({
  omschrijving: v =>
    v.aanvullendeGegevens && v.aanvullendeGegevens.toelichting ? v.aanvullendeGegevens.toelichting : ""
});

const mapInventarisatieStudielening = createMapToUi(InventarisatieStudieleningSchema).from<AanvraagLeningDlEntry>({
  aanvrager1HdnCode: v => v.inventarisatieStudielening?.hdnCodeAanvrager1?.toString().padStart(2, "0"),
  aanvrager2HdnCode: v => v.inventarisatieStudielening?.hdnCodeAanvrager2?.toString().padStart(2, "0")
});

function dl2ui(values: AanvraagLeningDlEntry): AanvraagLeningState {
  const aanvraagLening = {
    ...aanvraagLeningDefaults,
    aanvrager1: mapKlantnaamDl2Ui(values.aanvrager1),
    aanvrager2: mapKlantnaamDl2Ui(values.aanvrager2),
    voorstellen: mapVoorstellen(values),
    lening: mapLening(values),
    financiering: mapFinanciering(values),
    notaris: mapNotaris(values),
    maatwerkoplossing: mapMaatwerkoplossing(values),
    erfpacht: mapErfpacht(values),
    dataleverancier: mapDataleverancier(values),
    aanvullendeGegevens: mapAanvullendeGegevens(values),
    inventarisatieStudielening: mapInventarisatieStudielening(values)
  };

  return aanvraagLeningSchema.cast(aanvraagLening);
}

export function mapAanvraagLeningDlToUi(
  aanvraagLeningId: string,
  data: AanvraagLeningDlOutput
): AanvraagLeningState | null {
  const aanvraagLening = data && data.leningen ? data.leningen[aanvraagLeningId] : null;

  if (!aanvraagLening) {
    return null;
  }

  return dl2ui(aanvraagLening);
}

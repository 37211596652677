import { createSpanWithId, DataGrid, LabeledRadioInput, LabeledTextInput } from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement, useMemo, useState } from "react";
import { Column } from "react-table-6";
import { getAanvragerOpties } from "../../producten-overzicht/infra/producten-helper";
import Modal from "../../shared/components/modal/Modal";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { UseHdnDataResult } from "../../shared/hooks/use-hdn-data";
import AdviesBoxFormik from "../../shared/utils/adviesbox-formik";
import {
  maatwerkoplossingenEntrySchema,
  MaatwerkoplossingenEntryType,
  maatwerkoplossingModalValuesSchema,
  MaatwerkoplossingModalValuesType
} from "../infra/aanvraag-lening-schema";
import LabeledHdnKeuzelijst from "../../shared/components/hdn-keuzelijst/LabeledHdnKeuzelijst";

export type MaatwerkoplossingenModalData = {
  maatwerkLijst: {
    [index: string]: string;
  };
  maatschappijCode: string;
  aanvrager1: KlantnaamType | null;
  aanvrager2: KlantnaamType | null;
  data: MaatwerkoplossingenEntryType[];
  hdnOntvangerCode?: string;
};

export type MaatwerkoplossingenModalProps = {
  onSave?: (data: MaatwerkoplossingenEntryType[]) => void;
  closeModal?: () => void;
};

const MaatwerkoplossingenModal = ({
  maatwerkLijst,
  maatschappijCode,
  aanvrager1,
  aanvrager2,
  data,
  onSave,
  closeModal,
  hdnOntvangerCode
}: MaatwerkoplossingenModalData & MaatwerkoplossingenModalProps): ReactElement => {
  const mogelijkeMaatwerkoplossingen: string[] = useMemo(
    () => Object.keys(maatwerkLijst).filter(key => key.startsWith(maatschappijCode)),
    [maatwerkLijst, maatschappijCode]
  );

  const hdnKeuzelijstVoorDitVoorstel: UseHdnDataResult = useMemo(
    () =>
      mogelijkeMaatwerkoplossingen.reduce(
        (acc, key): UseHdnDataResult => {
          const reducedOptions = {
            error: null,
            hdnKeuzelijst: {
              ...acc.hdnKeuzelijst,
              [key]: maatwerkLijst[key]
            }
          };

          return reducedOptions;
        },
        { error: null as Error | null, hdnKeuzelijst: {} }
      ),
    [maatwerkLijst, mogelijkeMaatwerkoplossingen]
  );

  const selectedState = useState(0);
  const [selected] = selectedState;
  const options = getAanvragerOpties(aanvrager1, aanvrager2, false, true);

  const columns: Column[] = [
    {
      id: "modal",
      Header: "Reden",
      Cell: c => createSpanWithId(c.index, 0, maatwerkLijst[c.original.reden] ?? "-")
    },
    {
      Cell: "DeleteButton"
    }
  ];

  const body = (
    setFieldValue: FormikProps<MaatwerkoplossingenEntryType>["setFieldValue"],
    values: MaatwerkoplossingModalValuesType
  ): JSX.Element => (
    <>
      <DataGrid
        masterDetail
        columns={columns}
        name="modal"
        rowSelected={selectedState}
        validationSchema={maatwerkoplossingenEntrySchema}
        rowCaption="maatwerkoplossing"
        getNewRowValues={
          /* istanbul ignore next */ (): MaatwerkoplossingenEntryType => ({
            ...maatwerkoplossingenEntrySchema.default(),
            betrekkingOpKlantId: aanvrager1?.klantId ?? ""
          })
        }
      />

      {values.modal.length > 0 && (
        <>
          <LabeledRadioInput
            caption="Betrekking op"
            name={`modal[${selected}].betrekkingOpKlantId`}
            options={options}
            fieldSize={"xl"}
          />

          <LabeledHdnKeuzelijst
            caption="Type"
            tooltip="Specificeer a.u.b. de reden voor de maatwerkoplossing"
            keuzelijst="ExplainRedenType"
            berichtSoortType="AX"
            name={`modal[${selected}].seniorType`}
            ontvangerCode={hdnOntvangerCode}
            fieldSize={"xl"}
          />

          <LabeledHdnKeuzelijst
            caption="Reden"
            emptyValue={"Selecteer een optie"}
            name={`modal[${selected}].reden`}
            hdnData={hdnKeuzelijstVoorDitVoorstel}
            berichtSoortType="AX"
            keuzelijst="MaatwerkOplCodeMijType"
            ontvangerCode={hdnOntvangerCode}
            fieldSize={"xl"}
          />

          <LabeledTextInput
            caption="Toelichting"
            name={`modal[${selected}].toelichting`}
            textarea={4}
            fieldSize={"xl"}
          />
        </>
      )}
    </>
  );

  return (
    <AdviesBoxFormik<{ modal: MaatwerkoplossingenEntryType[] }>
      initialValues={{ modal: data }}
      validationSchema={maatwerkoplossingModalValuesSchema}
      onSave={(values): void => onSave && onSave(values.modal)}
      closeModal={closeModal}
      render={({ submitForm, setFieldValue, values }: FormikProps<MaatwerkoplossingModalValuesType>): ReactElement => (
        <>
          <Modal
            title="Maatwerkoplossingen"
            body={body(setFieldValue, values)}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
          />
        </>
      )}
    />
  );
};

MaatwerkoplossingenModal.displayName = "MaatwerkoplossingenModal";

export default MaatwerkoplossingenModal;

import { assertValue } from "adviesbox-shared";
import { TaxatieGebruikOptions, TaxatieToekomstigGebruikOptions, TaxatieSoortAanvraagOptions } from "../.generated/forms/formstypes";
import {
  ProductkenmerkenOutput,
  TaxatievormBasis,
  TaxatievormBasisOutput,
  SoortTaxatieProductOptions
} from "../.generated/producten/productentypes";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../shared/components/isw-side-effects/create-isw-helpers";
import { ISWAsyncSideEffectBag } from "../shared/components/isw-side-effects/isw-types";
import { createMapEnum } from "../shared/utils/create-map-enum";
import {
  editKenmerken,
} from "./infra/taxatie-bureaus-helper";
import {  soortAanvraagLabelValuePairs,
  taxatieBaseKenmerken,
  TaxatieKenmerkenType,
  TaxatieSchermType,
  taxatieSchermSchema,
  TaxatiebureauSchema,
  TaxatieAlgemeenType,
} from "./infra/taxatie-scherm-schema";

type AsyncContext = {
  kenmerken: TaxatieKenmerkenType;
};

const mapTaxatieSoortAanvraagOptions = createMapEnum(SoortTaxatieProductOptions).to({
  AanVerkoopBeslissing: TaxatieSoortAanvraagOptions.AanVerkoopBeslissing,
  Anders: TaxatieSoortAanvraagOptions.Anders,
  BouwkundigeKeuring: TaxatieSoortAanvraagOptions.BouwkundigeKeuring,
  Combinatie: TaxatieSoortAanvraagOptions.Combinatie,
  Oversluiting: TaxatieSoortAanvraagOptions.Oversluiting
});

export const initializeSoortAanvraagOpties = (vormen: {
  [key: string]: TaxatievormBasis;
}): soortAanvraagLabelValuePairs[] => {
  const productParticulierPlatformSpelling = "Particulier taxatieaanvraag";
  const productParticulierFEspelling = "Particuliere taxatieaanvraag";
  return Object.keys(vormen)
    .map(
      key =>
        vormen && {
          label:
            vormen[key].productnaam === productParticulierPlatformSpelling
              ? productParticulierFEspelling
              : vormen[key].productnaam ?? /* istanbul ignore next */ "",
          productCode: vormen[key].code,
          value: mapTaxatieSoortAanvraagOptions(vormen[key].soortTaxatieProduct) ??  /* istanbul ignore next */ ""
        }
    )
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
};

/* istanbul ignore next */
const setDefaultValues = async ({
  draft,
  context
}: ISWAsyncSideEffectBag<TaxatieSchermType, AsyncContext>): Promise<void> => {
  /* istanbul ignore else */
  if (draft.taxatieObject) {
    draft.taxatieObject.gebruikToekomst =
      context?.kenmerken?.GebruikToekomstTonen === true
        ? draft.taxatieObject?.gebruikToekomst ?? TaxatieToekomstigGebruikOptions.BenEigenaarEnBlijfInWoning
        : /* istanbul ignore next */ null;
    draft.taxatieObject.gebruikMomenteel =
      context?.kenmerken?.GebruikTonen === true
        ? draft.taxatieObject?.gebruikMomenteel ?? TaxatieGebruikOptions.Leegstaand
        : /* istanbul ignore next */ null;
  }
};

const getSortedVormen = (taxatieVormen: { [key: string]: TaxatievormBasis } ): TaxatievormBasis[] => {
  let sortedVormen:TaxatievormBasis[] = [];
  Object.keys(taxatieVormen).forEach(key => {
    const vorm = taxatieVormen[key]
    sortedVormen.push(vorm);
  });
  sortedVormen = sortedVormen.sort((n1,n2) =>  Object.keys(SoortTaxatieProductOptions).indexOf(n1.soortTaxatieProduct) -  Object.keys(SoortTaxatieProductOptions).indexOf(n2.soortTaxatieProduct))
  return sortedVormen;
}

export const taxatieMaatschappijCodeAsyncSideEffects = createISWAsyncSideEffect<TaxatieSchermType>(async bag => {
  const { draft,has, settings, fetchData } = bag;
  // indien Taxatiebureau op 'Selecteer een optie' wordt teruggezet, dan wordt de hele state leeg gemaakt
  try {
    assertValue(draft.taxatiebureau);
} catch (e) {
    const newState = { ...taxatieSchermSchema.default() };
    const { opdrachtgever, bouwkundigeKeuring, partner, aanvullendeInformatie, taxatieAlgemeen, taxatieObject, taxatiebureau } = newState;

    draft.opdrachtgever = opdrachtgever;
    draft.bouwkundigeKeuring = bouwkundigeKeuring;
    draft.partner = partner;
    draft.aanvullendeInformatie = aanvullendeInformatie;
    draft.taxatieObject = taxatieObject;
    draft.taxatiebureau = taxatiebureau;
    
    draft.taxatieAlgemeen = {
        ...taxatieAlgemeen,
        verbouwingSpecificaties: draft.taxatieAlgemeen?.verbouwingSpecificaties ?? []
    } as TaxatieAlgemeenType | null;

    draft.taxatieKenmerken = { ...taxatieBaseKenmerken };

    await setDefaultValues({
        ...bag,
        context: { kenmerken: draft.taxatieKenmerken as TaxatieKenmerkenType }
    });
    return;
}

  // Indien er wel een maatschappij is geselecteerd, gaan we daar eerst de taxatievormen voor ophalen
  const taxatieVormenResult = await fetchData<TaxatievormBasisOutput>({
    method: "GET",
    url: `${settings?.productenOrigin}/Maatschappijcodes/${draft.taxatiebureau.maatschappijCode}/Taxatievormen`
  });
  
  // Wanneer de taxatie-vormen zijn opgehaald deze verwerken, en hiermee de productkenmerken ophalen
  /* istanbul ignore else */
  if (taxatieVormenResult  !=null && taxatieVormenResult.isValid && taxatieVormenResult.taxatievormen!=null) {
    const vormen:TaxatievormBasis[] =getSortedVormen(taxatieVormenResult.taxatievormen);
    let eerstBeschikbareProduct =  vormen[0].code;
    
    /* istanbul ignore else */
    if (has.taxatiebureau && !!draft.taxatiebureau.soortAanvraag) {
      eerstBeschikbareProduct = vormen.find(c=>c.soortTaxatieProduct === draft.taxatiebureau?.soortAanvraag as unknown as SoortTaxatieProductOptions)?.code || eerstBeschikbareProduct;
    }

    const url = `${settings.productenOrigin}/Maatschappijcodes/${draft.taxatiebureau.maatschappijCode}/Productcodes/${eerstBeschikbareProduct}/Taxatievormen/Productkenmerken`;
    const resKenmerken = await fetchData<ProductkenmerkenOutput>({
      method: "GET",
      url
    });

    //  De nieuwe state van het scherm houd de gekozen maatschappij en soortAanvraag vast, verder alles leeg
    /* istanbul ignore else */

    if (resKenmerken.isValid && resKenmerken.productkenmerkCategorieen?.length) {
      const newState = {...taxatieSchermSchema.default(),
        opdrachtgever: draft.opdrachtgever, 
        taxatiebureau:{...TaxatiebureauSchema.default(),
          maatschappijCode:draft.taxatiebureau.maatschappijCode,
          soortAanvraag: draft.taxatiebureau.soortAanvraag, 
          productCode: eerstBeschikbareProduct
        } 
      };

      /* Bij wisselen (?) proberen we over te nemen uit de state wat overgenomen kan worden */

      if (resKenmerken.productkenmerkCategorieen[0].productkenmerken?.KaartPartnerTonen){
         draft.partner = draft.partner ?? newState.partner;
      }
      else{
        draft.partner = null;
      };
      
      if (resKenmerken.productkenmerkCategorieen[0].productkenmerken?.KaartBouwkundigeKeuringTonen){
        draft.bouwkundigeKeuring = draft.bouwkundigeKeuring ?? newState.bouwkundigeKeuring;
      }
      else{
        draft.bouwkundigeKeuring = null;
      }

      if (resKenmerken.productkenmerkCategorieen[0].productkenmerken?.KaartAanvullendeInformatieTonen){
        draft.aanvullendeInformatie = draft.aanvullendeInformatie ?? newState.aanvullendeInformatie;
      }
      else{
        draft.aanvullendeInformatie = null;
      }

      if(resKenmerken.productkenmerkCategorieen[0].productkenmerken?.KaartContactpersoonTonen){
        draft.contactpersoon = draft.contactpersoon ?? newState.contactpersoon;
      }
      else{
        draft.contactpersoon = null;
      }

      if(resKenmerken.productkenmerkCategorieen[0].productkenmerken?.KaartObjectTonen){
        draft.taxatieObject = draft.taxatieObject ?? newState.taxatieObject;
      }
      else{
        draft.taxatieObject = null;
      }

      draft.taxatieAlgemeen = newState.taxatieAlgemeen;      
      draft.taxatieKenmerken =  { ...taxatieBaseKenmerken};      
      draft.taxatieKenmerken = editKenmerken(newState.taxatiebureau.maatschappijCode,newState.taxatiebureau.soortAanvraag, 
          {
            ...taxatieBaseKenmerken,
            ...resKenmerken.productkenmerkCategorieen[0].productkenmerken
          }
      );

       await setDefaultValues({ ...bag, context: { kenmerken: draft.taxatieKenmerken as TaxatieKenmerkenType } });
    }
  }

  /* istanbul ignore next */
  if (taxatieVormenResult.isValid && typeof taxatieVormenResult !== "string" && taxatieVormenResult?.taxatievormen) {
    const vormen = taxatieVormenResult.taxatievormen;
    draft.TaxatieSoortAanvraagOptionsOpties = initializeSoortAanvraagOpties(vormen);
    if (draft.TaxatieSoortAanvraagOptionsOpties.length === 1 && draft.taxatiebureau != null) {
      draft.taxatiebureau.soortAanvraag = draft.TaxatieSoortAanvraagOptionsOpties[0]
        .value as TaxatieSoortAanvraagOptions;
      draft.taxatiebureau.productCode = draft.TaxatieSoortAanvraagOptionsOpties[0].productCode;
      draft.taxatiebureau.taxatieProductNaam = draft.TaxatieSoortAanvraagOptionsOpties[0].label;
    }
  }
});

export const determineTaxatieAsyncSideEffects = initISWAsyncSideEffect<TaxatieSchermType>(({ has, curr, runAsync }) => {
  if (has.taxatiebureau?.maatschappijCode.changed  || has.taxatiebureau?.soortAanvraag.changed ){
    runAsync(taxatieMaatschappijCodeAsyncSideEffects());
  }    
});

import * as Yup from "yup";
import { InferType } from "yup";
import { GeslachtOpties } from "../../.generated/forms/formstypes";
import { adresSchema } from "../../shared/generic-parts/adres/schema";
import { klantnaamSchema } from "../../shared/generic-parts/klantnaam/schema";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getAanvraagLeningTextResources } from "./aanvraag-lening-resources";
import { nullableLocalDateSchema } from "adviesbox-shared";

export enum BetaalwijzeAdvieskosten {
  MeefinancierenInHypotheek = "01",
  VoldoenUitEigenMiddelen = "02",
  Geen = "Geen"
}

export enum RedenAankoop {
  WoningEigenGebruik = "WoningEigenGebruik",
  TweedeWoning = "TweedeWoning",
  DeelsVerhuurd = "DeelsVerhuurd",
  GeheelVerhuurd = "GeheelVerhuurd"
}

export enum SoortOversluiting {
  InterneOversluiting = "InterneOversluiting",
  OversluitingAndereGeldgever = "OversluitingAndereGeldgever"
}

export enum AanleidingGesprek {
  Geen = "Geen",
  Verbouwing = "Verbouwing",
  AfkoopErfpacht = "AfkoopErfpacht",
  ExtraAflossen = "ExtraAflossen",
  Echtscheiding = "Echtscheiding",
  EindeRentevastperiode = "EindeRentevastperiode",
  AflossingsvormAanpassen = "AflossingsvormAanpassen",
  MaandlastenVerlichting = "MaandlastenVerlichting",
  Risicoscan = "Risicoscan",
  Vermogensadvies = "Vermogensadvies",
  Controlegesprek = "Controlegesprek",
  FinancieringWoning = "FinancieringWoning",
  Overig = "Overig"
}

export enum SoortFinanciering {
  Geen = "Geen",
  AankoopBestaandeBouw = "AankoopBestaandeBouw",
  AankoopNieuwbouw = "AankoopNieuwbouw",
  Oversluiten = "Oversluiten",
  Heropname = "Heropname",
  _2eHypotheek = "_2eHypotheek",
  _3eHypotheek = "_3eHypotheek",
  _4eHypotheek = "_4eHypotheek",
  Omzetting = "Omzetting",
  _1eHypotheek = "_1eHypotheek"
}

const phoneNumberAllowEmptyRegex = /^(((((\+|00)31|0)[\s-]{0,1}([0-9][\s-]{0,1}){9}|((\+|00)(?!31)[0-9]{2}([0-9][\s-]{0,1}){1,13})))|)$/;

export const voorstelSchema = Yup.object({
  voorstelId: Yup.string().default(""),
  volgnummer: Yup.number().default(0),
  omschrijving: Yup.string().default(""),
  inschrijvingBedrag: Yup.number()
    .nullable()
    .default(null),
  maatschappijCode: Yup.string().default(""),
  hdnOntvangerCode: Yup.string().default(""),
  passeerdatum: Yup.string().default(""),
  nhg: Yup.boolean().default(false),
  soortFinanciering: yupEnum(SoortFinanciering).default(SoortFinanciering.Geen),
  erfpacht: Yup.boolean().default(false),
  heeftNieuwLeningdeel: Yup.boolean().default(false),
  isMeeneemHypotheek: Yup.boolean().default(false),
});
export type VoorstelType = InferType<typeof voorstelSchema>;

export const voorstellenSchema = Yup.object({
  voorstellen: Yup.array()
    .of(Yup.object<VoorstelType>())
    .default([])
});
export type VoorstellenType = InferType<typeof voorstellenSchema>;

export const leningSchema = Yup.object({
  geselecteerdVoorstel: Yup.object<VoorstelType>(),
  gewensteHypotheek: Yup.string().default(""),
  inschrijvingsbedrag: Yup.number()
    .nullable()
    .default(null),
  bespreekzaak: Yup.boolean().default(false),
  verduurzamingBesproken: Yup.boolean().default(false),

  soortAdvies: Yup.string().default("02"),
  nettoAdvieskosten: Yup.number()
    .nullable()
    .default(null),
  betaalwijzeAdvieskosten: yupEnum(BetaalwijzeAdvieskosten).default(BetaalwijzeAdvieskosten.Geen)
});
export type LeningType = InferType<typeof leningSchema>;

export const maatwerkoplossingenEntrySchema = Yup.object({
  maatwerkRedenId: Yup.string()
    .nullable()
    .default(""),
  volgnummer: Yup.number().default(0),
  betrekkingOpKlantId: Yup.string().default(""),
  reden: Yup.string()
    .default("")
    .required(getAanvraagLeningTextResources("maatwerkoplossingRedenValidatieText")),
  toelichting: Yup.string()
    .max(255, getAanvraagLeningTextResources("ErrorMaxLengte").replace("{lengte}", "255 tekens"))
    .default("")
    .when("maatwerkRedenId", {
      is: (value: string): boolean => {
        return value.toLowerCase().includes("overig");
      },
      then: Yup.string().required(getAanvraagLeningTextResources("toelichtingBijOverigText")),
      otherwise: Yup.string().nullable()
    }),
  seniorType: Yup.string().nullable()
});
export type MaatwerkoplossingenEntryType = InferType<typeof maatwerkoplossingenEntrySchema>;

export const maatwerkoplossingModalValuesSchema = Yup.object({
  modal: Yup.array(maatwerkoplossingenEntrySchema)
});
export type MaatwerkoplossingModalValuesType = Yup.InferType<typeof maatwerkoplossingModalValuesSchema>;

export const maatwerkoplossingSchema = Yup.object({
  maatwerkoplossing: Yup.boolean().default(false),
  maatwerkredenen: Yup.array(maatwerkoplossingenEntrySchema)
    .when('maatwerkoplossing', {
      is: true,
      then: (schema: Yup.ArraySchema<typeof maatwerkoplossingenEntrySchema>) =>
        schema.min(1, 'Maatwerkredenen is required when maatwerkoplossing is true'),
      otherwise: (schema: Yup.ArraySchema<typeof maatwerkoplossingenEntrySchema>) => schema.notRequired(),
    }),
});
export type MaatwerkoplossingType = InferType<typeof maatwerkoplossingSchema>;

export const notarisSchema = Yup.object({
  notariskantoorId: Yup.string().default(""),
  kantoornaam: Yup.string().default(""),
  typeNotaris: Yup.string().default(""),
  adres: adresSchema,
  telefoonnummer: Yup.string()
    .default("")
    .matches(phoneNumberAllowEmptyRegex, "Ongeldig telefoonnummer."),
  geslacht: yupEnum(GeslachtOpties).default(GeslachtOpties.Geen),
  persoonsgegevens: klantnaamSchema
});
export type NotarisType = InferType<typeof notarisSchema>;

export const financieringSchema = Yup.object({
  redenAanvraag: Yup.string().default("01"),
  redenAankoop: yupEnum(RedenAankoop).default(RedenAankoop.WoningEigenGebruik),
  soortOversluiting: yupEnum(SoortOversluiting).default(SoortOversluiting.InterneOversluiting),
  aanleidingGesprek: yupEnum(AanleidingGesprek).default(AanleidingGesprek.Geen),
  verbouwingskosten: Yup.number()
    .nullable()
    .default(null)
});
export type FinancieringType = InferType<typeof financieringSchema>;

export const erfpachtSchema = Yup.object({
  ingangsdatum: nullableLocalDateSchema
});
export type ErfpachtType = InferType<typeof erfpachtSchema>;

export const dataleverancierSchema = Yup.object({
  dataleveranciers: Yup.array(
    Yup.object({
      hdnCode: Yup.string().default(""),
      geselecteerd: Yup.boolean().default(false)
    })
  )
});

export type DataleverancierType = InferType<typeof dataleverancierSchema>;

export const aanvullendeGegevensSchema = Yup.object({
  omschrijving: Yup.string().default("")
});

export type AanvullendeGegevensType = InferType<typeof aanvullendeGegevensSchema>;

export const InventarisatieStudieleningSchema = Yup.object({
  aanvrager1HdnCode: Yup.string()
    .nullable()
    .default(null),
  aanvrager2HdnCode: Yup.string()
    .nullable()
    .default(null)
});

export type InventarisatieStudieleningType = InferType<typeof InventarisatieStudieleningSchema>;

export const aanvraagLeningSchema = Yup.object({
  aanvrager1: klantnaamSchema.nullable(),
  aanvrager2: klantnaamSchema.nullable().default(null),
  voorstellen: voorstellenSchema,
  lening: leningSchema,
  maatwerkoplossing: maatwerkoplossingSchema,
  notaris: notarisSchema,
  financiering: financieringSchema,
  erfpacht: erfpachtSchema,
  dataleverancier: dataleverancierSchema,
  aanvullendeGegevens: aanvullendeGegevensSchema,
  inventarisatieStudielening: InventarisatieStudieleningSchema.nullable()
});
export type AanvraagLeningState = InferType<typeof aanvraagLeningSchema>;
export type AanvraagLeningProps = InferType<typeof aanvraagLeningSchema>;

import React, { ReactElement, useState, useEffect } from "react";

import { CardWrapper, PageLoading, AdviesBoxColumn, createSpanWithId, DataGrid } from "adviesbox-shared";
import { RapportageState, RapportageProps } from "./infra/rapportage-schema";

import { FormikContextType } from "formik";
import { Card } from "@material-ui/core";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { rapportageElementSchema } from "../shared/components/rapportage/rapportage-samenstellen-schema";
import { SaveButton } from "../shared/components/save-button/save-button";

import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import RapportageDetails from "./rapportage-details";
import { WithSaveData } from "../shared/utils/save-data";

const getRapportageKolommen = (): AdviesBoxColumn[] => {
  return [
    {
      Header: "Naam",
      Cell: (c): ReactElement =>
        createSpanWithId(c.index, 1, `${c.original.ParentHoodstukken.map((_: any) => `- `)}${c.original.naam}`)
    },
    { Header: "", Cell: (c): ReactElement => createSpanWithId(c.index, 0, null) }
  ];
};

const Rapportage = (props: FormikContextType<RapportageState> & RapportageProps): ReactElement => {
  const { isSubmitting, values, dirty, setIsDirty } = props;
  const { elementen, filteredElementen } = values;

  const selectedState = useState(0);
  const [selected] = selectedState;
  const selectedElementIndex = elementen.findIndex(x => x.code === filteredElementen[selected].code);

  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty, setIsDirty]);

  return (
    <div className="d-flex content_wrapper">
      <div className="content">
        {/* istanbul ignore next */ isSubmitting && <PageLoading />}
        <div className="save-btn-position">
          <div className="button-container">
            <SaveButton context={props} />
          </div>
        </div>
        <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={1}>
          <div className="p-1 w-xl-100 w-lg-100 w-md-50 w-50">
            <Card>
              <DataGrid
                masterDetail={true}
                rowCaption="Rapportage elementen"
                columns={getRapportageKolommen()}
                name="filteredElementen"
                rowSelected={selectedState}
              />
            </Card>
          </div>
        </CardWrapper>
        <RapportageDetails selected={selectedElementIndex} />
        <DevDebug />
      </div>
    </div>
  );
};

export default withAdviesboxFormik<RapportageProps & WithSaveData<RapportageState>, RapportageState>({
  mapPropsToValues: (e: RapportageProps): RapportageState => {
    const { setIsDirty, ...state } = e;
    return state;
  },
  validationSchema: rapportageElementSchema
})(Rapportage);

import { useContext, useEffect, useCallback } from "react";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";
import { useFetchData } from "./usefetchdata";

export type HdnBerichtSoortType = "AX" | "DA" | "DX" | "KX" | "LX";
export type UseHdnDataResult = {
  error: null | Error;
  hdnKeuzelijst: { [index: string]: string };
};

export function useHdnData(berichtSoortType: HdnBerichtSoortType, keuzelijst: string, ontvangerCode?: string | null): UseHdnDataResult {
  const loadingDone = useContext(ForceRerenderContext);

  const {
    requestInit,
    settings: { hdnKeuzelijstenOrigin }
  } = useRequestInit();
  const url = ontvangerCode ?  
  `${hdnKeuzelijstenOrigin}/${berichtSoortType}/ontvangerCodes/${ontvangerCode}/${keuzelijst}` :
  `${hdnKeuzelijstenOrigin}/${berichtSoortType}/${keuzelijst}`;  

  const getRequestInit = useCallback(/* istanbul ignore next */() => {
    return {
      ...requestInit,
    };
  }, [requestInit]);

  const { error, data, loading } = useFetchData<UseHdnDataResult>(url, getRequestInit, ontvangerCode == null || !!ontvangerCode);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  const listWithLoadingEntry = { "-1": "Laden..." };

  if (typeof data === "string") {
    return { error: new Error("Fout bij het laden van HDN data"), hdnKeuzelijst: {} };
  }

  return { error: error ? new Error(error) : null, hdnKeuzelijst: !data || !data.hdnKeuzelijst ? listWithLoadingEntry : data.hdnKeuzelijst };
}

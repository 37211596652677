/* istanbul ignore file */
/* eslint-disable */
import React from "react";
import { Table } from "react-bootstrap";
import { LabeledRadioInput } from "adviesbox-shared";
import "./scenario-table.scss";
import classNames from "classnames";
import {
  InkomensanalyseResultaat,
  Inkomensanalyse,
  ToelichtingRegel,
  ToelichtingOptions,
  InkomensanalyseNbiOverzichtJaar
} from "../../../.generated/rapportage/rapportagetypes";
import { TableNettoBesteedbaarInkomen } from "./nbi";
import { ScenarioBaseProps } from "./scenario-drawer";
import { Formik } from "formik";
import { Direction } from "../../../shared/types";
import { monthDivision, bedragFormatNull, getConclusieArcering, getMaandlastKeuzeTekst } from "./infra/scenario-utils";
import { ControleMaandlastOptions } from "../../../.generated/forms/formstypes";
import { CardLoadingSpinner } from "./card-loading-spinner";
import { getNaam, numberToString } from "../../../shared/utils/helpers";
import { CitationProvider, excludedFootnotes, CitationRef, CitationNote } from "./citation-notes";

export type ArbeidsongeschiktheidProps = {
  inkomensanalyseResultaten: InkomensanalyseResultaat[];
  totaalHuidigBrutoJaarinkomenBedrag: number | null;
  motiveringInkomensanalyse: Inkomensanalyse | null;
  nbiWeergeven: boolean;
  titel: string;
  conclusieEerste: string;
  conclusieTweede: string;
  conclusieDerde: string;
  nbi: InkomensanalyseNbiOverzichtJaar[];
  nbiVoetnoot: string;
  inkomensData?: {
    persoon: string;
    inkomenAanvragerEerstePeriode: number | null;
    inkomenAanvragerTweedePeriode: number | null;
    inkomenAanvragerDerdePeriode: number | null;
    inkomenPartnerEerstePeriode: number | null;
    inkomenPartnerTweedePeriode: number | null;
    inkomenPartnerDerdePeriode: number | null;
    totaalInkomenEerstePeriode: number | null;
    totaalInkomenTweedePeriode: number | null;
    totaalInkomenDerdePeriode: number | null;
    beleggingInkomenEerste: number | null;
    beleggingInkomenTweede: number | null;
    beleggingInkomenDerde: number | null;
    beleggingInkomenPartnerEerste: number | null;
    beleggingInkomenPartnerTweede: number | null;
    beleggingInkomenPartnerDerde: number | null;
    verhuurInkomenEerste: number | null;
    verhuurInkomenTweede: number | null;
    verhuurInkomenDerde: number | null;
    verhuurInkomenPartnerEerste: number | null;
    verhuurInkomenPartnerTweede: number | null;
    verhuurInkomenPartnerDerde: number | null;
    inkomensverliesEerste: number | null;
    inkomensverliesTweede: number | null;
    inkomensverliesDerde: number | null;
    wgaUitkeringAanvragerEerstePeriode: number | null;
    wgaUitkeringAanvragerTweedePeriode: number | null;
    wgaUitkeringAanvragerDerdePeriode: number | null;
    ivaUitkeringAanvragerEerstePeriode: number | null;
    ivaUitkeringAanvragerTweedePeriode: number | null;
    ivaUitkeringAanvragerDerdePeriode: number | null;
    percentageAoEerste: number | null;
    percentageAoTweede: number | null;
    percentageAoDerde: number | null;
    woonlastenEerstePeriode: number | null;
    woonlastenTweedePeriode: number | null;
    woonlastenDerdePeriode: number | null;
    rvcEerste: number | null;
    rvcTweede: number | null;
    rvcDerde: number | null;
    invaliditeitspensioenEerstePeriode: number | null;
    invaliditeitspensioenTweedePeriode: number | null;
    invaliditeitspensioenDerdePeriode: number | null;
    brutoInkomenPartnerEerstePeriode: number | null;
    brutoInkomenPartnerTweedePeriode: number | null;
    brutoInkomenPartnerDerdePeriode: number | null;
  };
  jaarlijkseAnalyse?: {
    gewenstInkomen: number | null;
    maandelijksTekortEerste: number | null;
    maandelijksTekortTweede: number | null;
    maandelijksTekortDerde: number | null;
  };
  financieleGegevens?: {
    tekortWerkelijk: number | null;
    toegestaneLast: number | null;
    tekortWerkelijkeBrutolast: number | null;
    werkelijkeBrutoLast: number | null;
    maximaleHypotheekEerste: number | null;
    maximaleHypotheekTweede: number | null;
    maximaleHypotheekDerde: number | null;
    hypotheekSaldo: number | null;
    overschrijding: number | null;
    benodigdInkomenHypotheek: number | null;
    tekortOpInkomen: number | null;
    tekortOpInkomenTweede: number | null;
    tekortOpInkomenDerde: number | null;
    maandelijksTekortHypotheekEerste: number;
    maandelijksTekortHypotheekTweede: number;
    maandelijksTekortHypotheekDerde: number;
  };
  voetnoten: ToelichtingRegel[];
  benodigdKapitaal: number | null;
  benodigdKapitaalOmschrijving: string;
};

export const InkomensanalyseTabelArbeidsongeschiktheid: React.FC<ArbeidsongeschiktheidProps & ScenarioBaseProps> = ({
  conclusieEerste,
  conclusieTweede,
  conclusieDerde,
  inkomensData,
  jaarlijkseAnalyse,
  voetnoten,
  nbi,
  nbiVoetnoot,
  benodigdKapitaal,
  benodigdKapitaalOmschrijving,
  nbiWeergeven,
  weergevenOptions,
  selectedKlantId,
  onKlantIdChange,
  benodigdKapitaalWeergeven,
  controleWerkelijkeLastenWeergeven,
  brutoInkomensWensenWeergeven,
  brutoInkomensWensPercentage,
  controleMaandlast,
  controleMaandlastKeuze,
  maximaleHypotheekWeergeven,
  loading,
  inkomensanalyseResultaten,
  totaalHuidigBrutoJaarinkomenBedrag,
  motiveringInkomensanalyse
}) => {
  const columnStyleLabel = {
    width: "40%"
  };
  const columnStyle = {
    width: "20%",
    textAlign: "right" as const
  };

  const aanvrager =
    motiveringInkomensanalyse?.aanvrager1?.klantId === selectedKlantId
      ? motiveringInkomensanalyse.aanvrager1
      : motiveringInkomensanalyse?.aanvrager2;
  const partner =
    motiveringInkomensanalyse?.aanvrager1?.klantId === selectedKlantId
      ? motiveringInkomensanalyse.aanvrager2
      : motiveringInkomensanalyse?.aanvrager1;

  return (
    <CitationProvider voetnoten={voetnoten} excludedFootnotes={excludedFootnotes}>
      <div className={`container-fluid mt-2 px-2 ${classNames("", { spinner: loading })}`}>
        {loading && <CardLoadingSpinner />}
        {weergevenOptions.length > 0 && (
          <Formik initialValues={{ scenarioKlantId: selectedKlantId }} enableReinitialize onSubmit={() => {}}>
            {({ setFieldValue }) => (
              <div className={"col-md-6 pb-3"}>
                <LabeledRadioInput
                  name={"scenarioKlantId"}
                  caption={"Weergeven scenario van"}
                  options={weergevenOptions}
                  onChange={event => {
                    onKlantIdChange(event.target.value);
                    setFieldValue("scenarioKlantId", event.target.value);
                  }}
                  layout={Direction.Horizontal}
                />
              </div>
            )}
          </Formik>
        )}
        <Table striped bordered hover responsive className="scenario__table mb-4">
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
            <col style={columnStyle} />
            <col style={columnStyle} />
          </colgroup>
          <thead>
            <tr>
              <th>
                {!loading && (
                  <>
                    Inkomen over {numberToString(motiveringInkomensanalyse?.toetsjaar ?? 0)} jaar bij
                    arbeidsongeschiktheid van {getNaam(aanvrager ?? null, "Aanvrager 1")}
                  </>
                )}
              </th>
              <th className={"text-center"}>{inkomensData?.percentageAoEerste}% AO</th>
              <th className={"text-center"}>{inkomensData?.percentageAoTweede}% AO</th>
              <th className={"text-center"}>
                {(inkomensData?.percentageAoDerde || 0) > 0 ? inkomensData?.percentageAoDerde + "% AO" : ""}{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={"font-weight-bold"}>
              <td></td>
              <td className={"text-center"}>
                {inkomensData?.rvcEerste}% WERKEN <CitationRef type={ToelichtingOptions.Verdiencapaciteit1} />
              </td>
              <td className={"text-center"}>
                {inkomensData?.rvcTweede}% WERKEN <CitationRef type={ToelichtingOptions.Verdiencapaciteit2} />
              </td>
              <td className={"text-center"}>
                {inkomensData?.rvcDerde}% WERKEN <CitationRef type={ToelichtingOptions.Verdiencapaciteit3} />
              </td>
            </tr>
            <tr>
              <td>Restinkomen {getNaam(aanvrager ?? null, "Aanvrager 1")}</td>
              <td>{bedragFormatNull(inkomensData?.inkomenAanvragerEerstePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.inkomenAanvragerTweedePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.inkomenAanvragerDerdePeriode, monthDivision)}</td>
            </tr>
            <tr>
              <td>
                Woonlastenverzekering <CitationRef type={ToelichtingOptions.WoonlastenVerzekering} />{" "}
                <CitationRef type={ToelichtingOptions.Brutering} />
              </td>
              <td>{bedragFormatNull(inkomensData?.woonlastenEerstePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.woonlastenTweedePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.woonlastenDerdePeriode, monthDivision)}</td>
            </tr>
            <tr>
              <td>Uitkering IVA {getNaam(aanvrager ?? null, "Aanvrager 1")}</td>
              <td>{bedragFormatNull(inkomensData?.ivaUitkeringAanvragerEerstePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.ivaUitkeringAanvragerTweedePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.ivaUitkeringAanvragerDerdePeriode, monthDivision)}</td>
            </tr>
            <tr>
              <td>Uitkering WGA {getNaam(aanvrager ?? null, "Aanvrager 1")}</td>
              <td>{bedragFormatNull(inkomensData?.wgaUitkeringAanvragerEerstePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.wgaUitkeringAanvragerTweedePeriode, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.wgaUitkeringAanvragerDerdePeriode, monthDivision)}</td>
            </tr>
            <tr>
              <td>Invaliditeitspensioen</td>
              <td>{bedragFormatNull(inkomensData?.invaliditeitspensioenEerstePeriode || 0, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.invaliditeitspensioenTweedePeriode || 0, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensData?.invaliditeitspensioenDerdePeriode || 0, monthDivision)}</td>
            </tr>
            {(inkomensData?.beleggingInkomenEerste !== 0 ||
              inkomensData?.beleggingInkomenTweede !== 0 ||
              inkomensData?.beleggingInkomenDerde !== 0) && (
              <>
                <tr>
                  <td>
                    Inkomsten uit belegging(en) {getNaam(aanvrager ?? null, "Aanvrager 1")}{" "}
                    <CitationRef type={ToelichtingOptions.Brutering} />
                  </td>
                  <td>{bedragFormatNull(inkomensData?.beleggingInkomenEerste, monthDivision)}</td>
                  <td>{bedragFormatNull(inkomensData?.beleggingInkomenTweede, monthDivision)}</td>
                  <td>{bedragFormatNull(inkomensData?.beleggingInkomenDerde, monthDivision)}</td>
                </tr>
              </>
            )}
            {(inkomensData?.verhuurInkomenEerste !== 0 ||
              inkomensData?.verhuurInkomenTweede !== 0 ||
              inkomensData?.verhuurInkomenDerde !== 0) && (
              <>
                <tr>
                  <td>
                    Inkomsten uit verhuur {getNaam(aanvrager ?? null, "Aanvrager 1")}{" "}
                    <CitationRef type={ToelichtingOptions.Brutering} />
                  </td>
                  <td>{bedragFormatNull(inkomensData?.verhuurInkomenEerste, monthDivision)}</td>
                  <td>{bedragFormatNull(inkomensData?.verhuurInkomenTweede, monthDivision)}</td>
                  <td>{bedragFormatNull(inkomensData?.verhuurInkomenDerde, monthDivision)}</td>
                </tr>
              </>
            )}
            {motiveringInkomensanalyse?.aanvrager2 && (
              <>
                <tr>
                  <td>Inkomen {getNaam(partner ?? null, "Aanvrager 2")}</td>
                  <td>{bedragFormatNull(inkomensData?.inkomenPartnerEerstePeriode || 0, monthDivision)}</td>
                  <td>{bedragFormatNull(inkomensData?.inkomenPartnerTweedePeriode || 0, monthDivision)}</td>
                  <td>{bedragFormatNull(inkomensData?.inkomenPartnerDerdePeriode || 0, monthDivision)}</td>
                </tr>
                {(inkomensData?.beleggingInkomenPartnerEerste !== 0 ||
                  inkomensData?.beleggingInkomenPartnerTweede !== 0 ||
                  inkomensData?.beleggingInkomenPartnerDerde !== 0) && (
                  <>
                    <tr>
                      <td>
                        Inkomsten uit belegging(en) {getNaam(partner ?? null, "Aanvrager 2")}{" "}
                        <CitationRef type={ToelichtingOptions.Brutering} />
                      </td>
                      <td>{bedragFormatNull(inkomensData?.beleggingInkomenPartnerEerste, monthDivision)}</td>
                      <td>{bedragFormatNull(inkomensData?.beleggingInkomenPartnerTweede, monthDivision)}</td>
                      <td>{bedragFormatNull(inkomensData?.beleggingInkomenPartnerDerde, monthDivision)}</td>
                    </tr>
                  </>
                )}
                {(inkomensData?.verhuurInkomenPartnerEerste !== 0 ||
                  inkomensData?.verhuurInkomenPartnerTweede !== 0 ||
                  inkomensData?.verhuurInkomenPartnerDerde !== 0) && (
                  <>
                    <tr>
                      <td>
                        Inkomsten uit verhuur {getNaam(partner ?? null, "Aanvrager 2")}{" "}
                        <CitationRef type={ToelichtingOptions.Brutering} />
                      </td>
                      <td>{bedragFormatNull(inkomensData?.verhuurInkomenPartnerEerste, monthDivision)}</td>
                      <td>{bedragFormatNull(inkomensData?.verhuurInkomenPartnerTweede, monthDivision)}</td>
                      <td>{bedragFormatNull(inkomensData?.verhuurInkomenPartnerDerde, monthDivision)}</td>
                    </tr>
                  </>
                )}
              </>
            )}
            <tr className="font-weight-bold">
              <td>Inkomen totaal</td>
              <td>{bedragFormatNull(inkomensanalyseResultaten?.[0]?.totaalBrutoInkomenBedrag, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensanalyseResultaten?.[1]?.totaalBrutoInkomenBedrag, monthDivision)}</td>
              <td>{bedragFormatNull(inkomensanalyseResultaten?.[2]?.totaalBrutoInkomenBedrag, monthDivision)}</td>
            </tr>
            <tr className={"table-warning"}>
              <td>Inkomensverlies</td>
              <td>{bedragFormatNull(Math.abs(inkomensData?.inkomensverliesEerste || 0))}</td>
              <td>{bedragFormatNull(Math.abs(inkomensData?.inkomensverliesTweede || 0))}</td>
              <td>{bedragFormatNull(Math.abs(inkomensData?.inkomensverliesDerde || 0))}</td>
            </tr>
          </tbody>
        </Table>
        {brutoInkomensWensenWeergeven && (
          <>
            <Table striped bordered hover responsive className="scenario__table mb-4">
              <colgroup>
                <col style={columnStyleLabel} />
                <col style={columnStyle} />
                <col style={columnStyle} />
                <col style={columnStyle} />
              </colgroup>
              <tbody>
                <tr>
                  <td>Gewenst inkomen van minimaal {brutoInkomensWensPercentage}% van huidig bruto inkomen</td>
                  <td>
                    {bedragFormatNull(
                      (totaalHuidigBrutoJaarinkomenBedrag || 0) * ((brutoInkomensWensPercentage || 0) / 100),
                      monthDivision
                    )}
                  </td>
                  <td>
                    {bedragFormatNull(
                      (totaalHuidigBrutoJaarinkomenBedrag || 0) * ((brutoInkomensWensPercentage || 0) / 100),
                      monthDivision
                    )}
                  </td>
                  <td>
                    {bedragFormatNull(
                      (totaalHuidigBrutoJaarinkomenBedrag || 0) * ((brutoInkomensWensPercentage || 0) / 100),
                      monthDivision
                    )}
                  </td>
                </tr>
                <tr className={"table-danger font-weight-bold"}>
                  <td>Maandelijks tekort aan gewenste inkomsten</td>
                  <td>{bedragFormatNull(Math.abs(jaarlijkseAnalyse?.maandelijksTekortEerste || 0))}</td>
                  <td>{bedragFormatNull(Math.abs(jaarlijkseAnalyse?.maandelijksTekortTweede || 0))}</td>
                  <td>{bedragFormatNull(Math.abs(jaarlijkseAnalyse?.maandelijksTekortDerde || 0))}</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
        <Table striped bordered hover responsive className="scenario__table mb-4">
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
            <col style={columnStyle} />
            <col style={columnStyle} />
          </colgroup>
          <tbody>
            {controleWerkelijkeLastenWeergeven && (
              <>
                <tr>
                  <td>Toegestane last o.b.v. inkomen</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].toegestaneLastBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[1].toegestaneLastBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[2].toegestaneLastBedrag)}</td>
                </tr>
                <tr>
                  <td>Werkelijke bruto last van hypotheek en verpande producten</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].werkelijkeLastBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[1].werkelijkeLastBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[2].werkelijkeLastBedrag)}</td>
                </tr>
                <tr className={"table-danger font-weight-bold"}>
                  <td>Tekort o.b.v. werkelijke bruto last</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].tekortToegestaneLastBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[1].tekortToegestaneLastBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[2].tekortToegestaneLastBedrag)}</td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
        {controleMaandlast && controleMaandlastKeuze === ControleMaandlastOptions.BenodigdInkomen && (
          <Table striped bordered hover responsive className="scenario__table  mb-4">
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
              <col style={columnStyle} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  Minimaal benodigd inkomen voor uw hypotheek <CitationRef type={ToelichtingOptions.BrutoNettoTekort} />
                </td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[0]?.benodigdInkomenBedrag || 0, monthDivision)}</td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[1]?.benodigdInkomenBedrag || 0, monthDivision)}</td>
                <td>{bedragFormatNull(inkomensanalyseResultaten?.[2]?.benodigdInkomenBedrag || 0, monthDivision)}</td>
              </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Maandelijks tekort aan inkomsten voor uw hypotheek</td>
                <td>
                  {bedragFormatNull(inkomensanalyseResultaten?.[0]?.tekortBenodigInkomenBedrag || 0, monthDivision)}
                </td>
                <td>
                  {bedragFormatNull(inkomensanalyseResultaten?.[1]?.tekortBenodigInkomenBedrag || 0, monthDivision)}
                </td>
                <td>
                  {bedragFormatNull(inkomensanalyseResultaten?.[2]?.tekortBenodigInkomenBedrag || 0, monthDivision)}
                </td>
              </tr>
              <tr className={"table-danger font-weight-bold"}>
                <td>Netto maandelijks tekort voor uw hypotheek</td>
                <td>
                  {bedragFormatNull(inkomensanalyseResultaten?.[0]?.tekortNettoInkomenBedrag || 0, monthDivision)}
                </td>
                <td>
                  {bedragFormatNull(inkomensanalyseResultaten?.[1]?.tekortNettoInkomenBedrag || 0, monthDivision)}
                </td>
                <td>
                  {bedragFormatNull(inkomensanalyseResultaten?.[2]?.tekortNettoInkomenBedrag || 0, monthDivision)}
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        <Table striped bordered hover responsive className="scenario__table mb-4">
          <colgroup>
            <col style={columnStyleLabel} />
            <col style={columnStyle} />
            <col style={columnStyle} />
            <col style={columnStyle} />
          </colgroup>
          <tbody>
            {maximaleHypotheekWeergeven && (
              <>
                <tr>
                  <td>Maximale hypotheek</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].maximaleHypotheekBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[1]?.maximaleHypotheekBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[2]?.maximaleHypotheekBedrag)}</td>
                </tr>
                <tr>
                  <td>Hypotheeksaldo</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[0].hypotheeksaldoBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[1]?.hypotheeksaldoBedrag)}</td>
                  <td>{bedragFormatNull(inkomensanalyseResultaten?.[2]?.hypotheeksaldoBedrag)}</td>
                </tr>
                <tr className={"table-danger font-weight-bold"}>
                  <td>Overschrijding van uw maximale hypotheek</td>
                  <td>
                    {bedragFormatNull(
                      Math.abs(
                        Math.min(
                          (inkomensanalyseResultaten?.[0].maximaleHypotheekBedrag || 0) -
                            (inkomensanalyseResultaten?.[0].hypotheeksaldoBedrag || 0),
                          0
                        )
                      )
                    )}
                  </td>
                  <td>
                    {bedragFormatNull(
                      Math.abs(
                        Math.min(
                          (inkomensanalyseResultaten?.[1]?.maximaleHypotheekBedrag || 0) -
                            (inkomensanalyseResultaten?.[1]?.hypotheeksaldoBedrag || 0),
                          0
                        )
                      )
                    )}
                  </td>
                  <td>
                    {bedragFormatNull(
                      Math.abs(
                        Math.min(
                          (inkomensanalyseResultaten?.[2]?.maximaleHypotheekBedrag || 0) -
                            (inkomensanalyseResultaten?.[2]?.hypotheeksaldoBedrag || 0),
                          0
                        )
                      )
                    )}
                  </td>{" "}
                </tr>
              </>
            )}
          </tbody>
        </Table>

        {controleMaandlast && (
          <Table striped bordered hover responsive className={"mb-4"}>
            <colgroup>
              <col style={columnStyleLabel} />
              <col style={columnStyle} />
              <col style={columnStyle} />
              <col style={columnStyle} />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  De maandlast die overblijft voor deze situatie t.o.v. de{" "}
                  {getMaandlastKeuzeTekst(controleMaandlastKeuze)} ?
                </td>
                <td className={classNames("text-right", getConclusieArcering(conclusieEerste))}>{conclusieEerste}</td>
                <td className={classNames("text-right", getConclusieArcering(conclusieTweede))}>{conclusieTweede}</td>
                <td className={classNames("text-right", getConclusieArcering(conclusieDerde))}>{conclusieDerde}</td>
              </tr>
            </tbody>
          </Table>
        )}

        <p className="mt-3 px-2">
          {voetnoten?.map(footnote => (
            <CitationNote key={footnote.type} type={footnote.type} text={footnote.toelichting || ""} />
          ))}
        </p>

        {nbiWeergeven && (
          <TableNettoBesteedbaarInkomen
            startDatum={motiveringInkomensanalyse?.startdatum}
            nbi={nbi}
            voetnoot={nbiVoetnoot}
          />
        )}

        {benodigdKapitaalWeergeven && (
          <Table striped bordered hover responsive className={"scenario__table mb-4"}>
            <colgroup>
              <col style={{ width: "40%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Uw risico o.b.v. uw gewenste inkomen</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{benodigdKapitaalOmschrijving}</td>
                <td>{bedragFormatNull(benodigdKapitaal)}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </CitationProvider>
  );
};

import { FinancieringsbehoefteState, Erfpacht, Onderpand, Financieringsopzet } from "./financieringsbehoefte-types";
import { Financieringsoort } from "../../.generated/forms/formstypes";
import { initISWSideEffect, createISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { limitCurrent } from "../../shared/utils/helpers";
import { optellen } from "../../shared/utils/currency";
import { standaardStartersleningBedrag } from "./financieringsbehoefte-schema";

export type FinancieringsbehoefteContext = {
  modelStateModal: [boolean | null, React.Dispatch<React.SetStateAction<boolean | null>>];
};

const soortFinancieringChanged = (draft: FinancieringsbehoefteState): void => {
  // Overdrachtsbelasting en leveringsakte legen bij aanpassing soortfinanciering
  if (
    draft.financiering.soortFinanciering !== Financieringsoort.AankoopBestaandeBouw &&
    draft.financiering.soortFinanciering !== Financieringsoort.Oversluiten
  ) {
    draft.financieringsopzet.overdrachtsbelasting.bedrag = null;
    draft.financieringsopzet.leveringsakte.bedrag = null;
  }

  // Bij Omzetting is gewenste hypotheek altijd gelijk aan 0
  if (
    draft.financiering.soortFinanciering === Financieringsoort.Omzetting
  ) {
    draft.financieringsopzet.gewensteHypotheek = 0;
    draft.financieringsopzet.gewensteHypotheekBerekenen = false;
    draft.financieringsopzet.hypothecaireInschrijving = { bedrag: null, berekenen: false, berekendBedrag: null };
    draft.financieringsopzet.hypotheekakte = { bedrag: null, berekenen: false, berekendBedrag: null };
    draft.financieringsopzet.afTeLossenBestaandeHypotheken.bedrag = null;
    draft.financieringsopzet.afTeLossenBestaandeHypotheken.berekenen = false;
  }

  // Bij Heropname is geen hypotheekakte van toepassing
  if (
    draft.financiering.soortFinanciering === Financieringsoort.Heropname
  ) {
    draft.financieringsopzet.hypotheekakte = { bedrag: null, berekenen: false, berekendBedrag: null };
  }
}

const determineOnderpandSideEffects = createISWSideEffect<Onderpand>((bag): void => {
  const { has, draft } = bag;

  if (has.eigendomsverhoudingAanvrager.changed) {
    draft.eigendomsverhoudingAanvrager = limitCurrent(draft.eigendomsverhoudingAanvrager);
    draft.eigendomsverhoudingPartner = draft.heeftPartner ? 100 - limitCurrent(draft.eigendomsverhoudingAanvrager) : 0;
  } else if (has.eigendomsverhoudingPartner.changed) {
    draft.eigendomsverhoudingAanvrager = 100 - limitCurrent(draft.eigendomsverhoudingPartner);
    draft.eigendomsverhoudingPartner = limitCurrent(draft.eigendomsverhoudingPartner);
  }
});

const determineErfpachtSideEffects = createISWSideEffect<Erfpacht>((bag): void => {
  const { has, draft } = bag;

  if (has.erfpacht.changed && !draft.erfpacht) {
    draft.jaarlijksCanon = null;
    draft.particulierErfpacht = false;
    draft.eeuwigdurendErfpacht = false;
    draft.einddatum = null;
  }

  if (has.eeuwigdurendErfpacht.changed && draft.eeuwigdurendErfpacht) {
    draft.einddatum = null;
  }

  if (has.erfpacht.changed && !draft.erfpacht) {
    draft.jaarlijksCanon = null;
    draft.particulierErfpacht = false;
    draft.eeuwigdurendErfpacht = false;
    draft.einddatum = null;
    draft.passeerdatum = null;
  }
});

const determineFinancieringsopzetSideEffects = createISWSideEffect<Financieringsopzet>((bag): void => {
  const { has, draft } = bag;

  if (has.koopsom.changed || has.verbouwingVerbeteringModal.totaalVerbouwingVerbetering.changed) {
    draft.renteverliesTijdensBouwModal.grondkosten = draft.koopsom.kostenGrondBedrag;

    const stichtingOptel = optellen([
      draft.koopsom.koopsomBedragNieuwbouw,
      draft.verbouwingVerbeteringModal.totaalVerbouwingVerbetering
    ]);
    draft.renteverliesTijdensBouwModal.stichtingskosten = stichtingOptel
      ? stichtingOptel - (draft.koopsom.kostenGrondBedrag || 0)
      : 0;

    if (draft && draft.renteverliesTijdensBouwModal) {
      draft.renteverliesTijdensBouwModal.bouwdepotbedrag = draft.renteverliesTijdensBouwModal.stichtingskosten
        ? draft.renteverliesTijdensBouwModal.stichtingskosten - (draft.koopsom.kostenGrondBedrag || 0)
        : 0;
    }

    if (has.koopsom.koopsomBedragNieuwbouw.changed) {
      if (draft.koopsom.grondReedsInBezit) {
        draft.koopsom.koopsomBedrag =
          (draft.koopsom.koopsomBedragNieuwbouw || 0) + (draft.koopsom.kostenGrondBedrag || 0);
      } else {
        draft.koopsom.koopsomBedrag = draft.koopsom.koopsomBedragNieuwbouw || 0;
      }
    }
  }

  if (has.renteverliesTijdensBouwModal.grondkosten.changed) {
    draft.koopsom.kostenGrondBedrag = draft.renteverliesTijdensBouwModal.grondkosten;
  }

  if (has.andereFinancieringenModal.changed || has.gewensteHypotheek.changed) {
    draft.renteverliesTijdensBouwModal.hypotheekbedrag = optellen([
      draft.gewensteHypotheek,
      draft.andereFinancieringenModal.doorlopendeHypotheken,
      draft.andereFinancieringenModal.kortlopendeLeningKrediet,
      draft.andereFinancieringenModal.startersleningBedrag,
      draft.andereFinancieringenModal.overigeFinanciering1Bedrag,
      draft.andereFinancieringenModal.overigeFinanciering2Bedrag
    ]);
  }
});

const determineOverigeSideEffects = createISWSideEffect<FinancieringsbehoefteState, FinancieringsbehoefteContext>((bag): void => {
  const { has, draft, context } = bag;
  
  // Melding direct tonen bij eerste keer wijziging NHG wanneer van handmatig naar berekenen wordt gewijzigd
  const [modalState, setModalState] = context.modelStateModal;
  if (has.financieringsopzet.nationaleHypotheekGarantieBerekenen.changed &&
      draft.financieringsopzet.nationaleHypotheekGarantieBerekenen &&
    modalState === null) {
      setModalState(true);
  }
  
  // Als NHG handmatig wordt gewijzigd niet meer automatisch wijzigen op basis van EXT
  if(has.financieringsopzet.nationaleHypotheekGarantieBerekenen.changed && 
    !draft.financieringsopzet.nhgMogelijkIngesteld) {
    draft.financieringsopzet.nhgMogelijkIngesteld = true;
  }

  if (
    has.financiering.soortFinanciering.changed &&
    draft.financiering.doorlopendeLeningdelenBedrag &&
    draft.financiering.doorlopendeLeningdelenBedrag > 0
  ) {
    draft.cascadingValueChange = {
      hasChanged: true,
      elementsChanged: ["Soort financiering"]
    };
  }

  if (
    draft.financiering.soortFinanciering === Financieringsoort.AankoopBestaandeBouw &&
    has.financiering.kostenKoper.changed &&
    draft.financiering.kostenKoper === false
  ) {
    draft.financieringsopzet.overdrachtsbelasting.bedrag = null;
    draft.financieringsopzet.leveringsakte.bedrag = null;
  }

  // afsluitkostenSVnStarterslening legen wanneer er geen starterslening is
  const startersleningBedrag = draft.financieringsopzet?.andereFinancieringenModal?.startersleningBedrag;
  draft.financieringsopzet.overigeFinancieringskostenModal.afsluitkostenSVnStarterslening = startersleningBedrag  
    ? standaardStartersleningBedrag  
    : 0;  

  if (has.financiering.soortNieuwbouw.changed) {  
    draft.financiering.soortZelfbouw =  
      draft.financiering.soortNieuwbouw === "02" ? "01" : null;  
  }

  if (has.financiering.soortFinanciering.changed) {
    soortFinancieringChanged(draft);
  }
});

const financieringsbehoefteDraftSideEffects = createISWSideEffect<FinancieringsbehoefteState, FinancieringsbehoefteContext>((bag): void => {

  determineOverigeSideEffects(bag);
  determineOnderpandSideEffects(bag.subset.onderpand.create());
  determineErfpachtSideEffects(bag.subset.erfpacht.create());
  determineFinancieringsopzetSideEffects(bag.subset.financieringsopzet.create());
});

export const determineFinancieringsbehoefteSideEffects = initISWSideEffect<FinancieringsbehoefteState, FinancieringsbehoefteContext>(financieringsbehoefteDraftSideEffects);
import React, { ReactElement } from "react";
import { Button, Modal } from "react-bootstrap";

export type ValidatiePopupProps = {
  infotekst: string;
  show: boolean;
  onHide: () => void;
  onSubmit?: () => void;
  navigatiepopup?: boolean;
};
export const ValidatiePopup = ({ ...props }: ValidatiePopupProps): ReactElement => {
  return (
    <Modal show={props.show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Er zijn niet opgeslagen gegevens op deze pagina</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-modal">
        <p>
          Er zijn niet opgeslagen gegevens op deze pagina. Als je toch van voorstel wisselt dan wordt alle door jou
          gewijzigde data niet opgeslagen.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <>
          {props.navigatiepopup ? (
            <>
              <Button id="btnannuleren" data-testid="btnannuleren" variant="link" onClick={props.onHide}>
                Annuleren
              </Button>
              <Button id="btnopslaan" data-testid="btnopslaan" variant="danger" onClick={props.onSubmit}>
                Verwijder mijn aanpassingen en navigeer
              </Button>
            </>
          ) : (
            <Button id="btnopslaan" data-testid="btnopslaan" variant="primary" onClick={props.onHide}>
              Sluiten
            </Button>
          )}
        </>
      </Modal.Footer>
    </Modal>
  );
};

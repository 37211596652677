import React, { useState, ReactElement } from "react";
import RapportageSelect from "./rapportage-select";
import RapportageData from "./rapportage-data";

export const RapportageWrapper = (): ReactElement => {
  const [selectedVoorstel, setSelectedVoorstel] = useState<string>("Huidig");
  const [selectEnabled, setSelectEnabled] = useState<boolean>(true);

  return (
    <>
      <RapportageSelect
        setSelectedVoorstel={setSelectedVoorstel}
        selectedVoorstel={selectedVoorstel}
        isDirty={selectEnabled}
      />
      <RapportageData voorstel={selectedVoorstel} setIsDirty={setSelectEnabled} />
    </>
  );
};

export default RapportageWrapper;

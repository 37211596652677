import React from "react";
import { Dropdown } from "react-bootstrap";
import { Icon } from "adviesbox-shared";
import BestaandeRapporten from "./bestaande-rapporten";

const RapportenMenu = (): React.ReactElement => {
  return (
    <Dropdown className="top-navigation__item">
      <Dropdown.Toggle as="button" variant="link" id="naar-DOC" data-testid="naar-DOC" className="dropdown-toggle btn btn-link btn-sm">
        <Icon name="documenten" multiColor={true} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <BestaandeRapporten />
      </Dropdown.Menu>
    </Dropdown>
  );
};

RapportenMenu.displayName = "RapportenMenu";

export default RapportenMenu; 
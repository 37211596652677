import React, { useEffect, useState } from "react";
import { bestaandeRapporten, downloadRapport } from "./bestaande-rapporten-api";
import { useRequestInit, Icon } from "adviesbox-shared";
import "./bestaande-rapporten.scss";
import { Table } from "react-bootstrap";
import { CardLoadingSpinner } from "../../../dashboard/new/scenarios/card-loading-spinner";
import { Rapport } from "../../../.generated/rapportage/rapportagetypes";

const BestaandeRapporten: React.FC = (): JSX.Element => {
  const [rapporten, setRapporten] = useState<Rapport[] | null | undefined>();
  const { settings, user, params } = useRequestInit();
  const [downloading, setDownloading] = useState<string | null>(null);

  useEffect(() => {
    const getRapporten = async (): Promise<void> => {
      const rapporten = await bestaandeRapporten(settings, user, params.vestiging || /* istanbul ignore next */ "", params.adviesdossier || /* istanbul ignore next */ "");
      setRapporten(rapporten);
    };

    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    getRapporten();
  }, [settings, user, params]);

  const getType = (omschrijving: string | null): string => {
    return (
      omschrijving
        ?.split(" ")
        .slice(0, -2)
        .join(" ") || ""
    );
  };

  /* istanbul ignore next */
  const formatDate = (date: string | null): string => {
    if (!date) return "";
    return new Date(date).toLocaleDateString("nl-NL");
  };

  const handleDownload = async (rapport: Rapport): Promise<void> => {
    if (downloading) return;
    setDownloading(rapport.rapportId);
    try {
      const result = await downloadRapport(
        settings,
        user,
        params.vestiging || /* istanbul ignore next */ "",
        params.adviesdossier || /* istanbul ignore next */ "",
        rapport.rapportId
      );

      /* istanbul ignore else */
      if (result) {
        const { blob, filename } = result;
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      if (error instanceof Error) {
        setRapporten(null);
      }
    } finally {
      setDownloading(null);
    }
  };

  return (
    <div className="bestaande-rapporten dropdown__popup">
      <div className="dropdown__title mr-auto">
        <span>Documenten</span>
        <span>({ rapporten?.length ?? 0 })</span>
      </div>
      {(!rapporten || rapporten?.length === 0) && (
        <div className="no-data delayed-show">Er zijn geen rapporten gevonden</div>
      )}
      <div className="dropdown__body">
      { rapporten === undefined && <div className="mx-auto"><CardLoadingSpinner /></div>}

      { rapporten && rapporten.length > 0 && (
        <Table responsive striped hover>
          <thead>
            <tr>
              <th>Type</th>
              <th>Bestandsnaam</th>
              <th>Aanmaakdatum</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rapporten.map(rapport => (
              <tr key={rapport.rapportId}>
                <td>{getType(rapport.omschrijving)}</td>
                <td>{rapport.omschrijving}</td>
                <td>{formatDate(rapport.aanmaakdatum)}</td>
                <td>
                  <button
                    className="no-btn p-1 btn-link"
                    onClick={() => handleDownload(rapport)}
                    disabled={downloading === rapport.rapportId}
                  >
                    <Icon name={downloading === rapport.rapportId ? "loading" : "download"} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      </div>
    </div>
  );
};

export default BestaandeRapporten;

import { rapportageSchema } from "./rapportage-schema";
import { RapportSamenstellenStructuur } from "../../.generated/forms/formstypes";
import { unflattenElements } from "./rapportage-helper";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";

export const mapRapportageToDl = createMapToDl(rapportageSchema).to<RapportSamenstellenStructuur>({
  elementen: v => unflattenElements(v.elementen),
  naam: v => v.naam,
  structuurId: v => v.structuurId,
  volgnummer: v => v.volgnummer
});

import { FormikErrors, getIn, setIn } from "formik";
import { AcceptatieAanvrager, KlantnaamReadOnly } from "../../.generated/forms/formstypes";
import { KlantnaamType } from "../../shared/generic-parts/klantnaam/schema";
import { UiError } from "../types";

export function capitalizeFirstLetter(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function sleep(ms = 10): Promise<void> {
  return new Promise((resolve): void => {
    setTimeout((): void => resolve(), ms);
  });
}

export function assertNever(value: never): never {
  throw new Error("Unexpected value: " + value);
}

export function getFieldSizeClass(size: "xl" | "l" | "m" | "sm" | "s" | "grid-cell" | "no-size" | "full-size"): string {
  switch (size) {
    case "xl":
      return "extra-large";
    case "l":
      return "large";
    case "m":
      return "medium";
    case "sm":
      return "smedium";
    case "s":
      return "small";
    case "grid-cell":
      return "grid-cell";
    case "no-size":
      return "no-size";
    case "full-size":
      return "full-size";
    default:
      return assertNever(size);
  }
}

export function prefixWithZero(value: number, size?: number): string;
export function prefixWithZero(value: number | null | undefined, size?: number): string | null;
export function prefixWithZero(value: number | null | undefined, size = 2): string | null {
  if (typeof value === "number") {
    return value.toString().padStart(size, "0");
  }

  return null;
}

export const limitCurrent = (current: number | null): number =>
  !hasValue(current) ? 0 : current > 100 ? 100 : current < 0 ? 0 : current;

export const getSum = (input: (number | null | undefined)[]): number => {
  return input.reduce((x, y): number => (x || 0) + (y || 0), 0) || 0;
};

export const getNaam = (
  persoonsgegevensState: KlantnaamType | AcceptatieAanvrager | KlantnaamReadOnly | null,
  fallbackNaam: string
): string => {
  if (!persoonsgegevensState) {
    return fallbackNaam;
  }
  let voor = persoonsgegevensState.voorletters?.trim() ?? null;
  if (voor === null || voor === "") voor = persoonsgegevensState.voornamen;
  const achternaam = `${persoonsgegevensState.achternaam}`;
  const voorAchternaam = `${voor} ${persoonsgegevensState.achternaam}`;
  const tussenAchternaam = `${persoonsgegevensState.voorvoegsel} ${persoonsgegevensState.achternaam}`;
  const voorTussenAchternaam = `${voor} ${persoonsgegevensState.voorvoegsel} ${persoonsgegevensState.achternaam}`;
  const defaultNaam = `${fallbackNaam}`;

  if (persoonsgegevensState.achternaam && voor && persoonsgegevensState.voorvoegsel) {
    return voorTussenAchternaam;
  }
  if (persoonsgegevensState.achternaam && voor && !persoonsgegevensState.voorvoegsel) {
    return voorAchternaam;
  }
  if (persoonsgegevensState.achternaam && !voor && !persoonsgegevensState.voorvoegsel) {
    return achternaam;
  }

  if (persoonsgegevensState.achternaam && !voor && persoonsgegevensState.voorvoegsel) {
    return tussenAchternaam;
  }

  return defaultNaam;
};

export const keysToLowerCaseFirst = (obj: any): any => {
  Object.keys(obj).forEach(function (key): any {
    // only first char gets lowercased :-)
    const k = key.charAt(0).toLowerCase() + key.slice(1);

    if (k !== key) {
      obj[k] = obj[key];
      delete obj[key];
    }
  });
  return obj;
};

export function voegApiFoutToe<Values>(formikErrors: {}, platformApiFouten: UiError[] | null): FormikErrors<Values> {
  if (platformApiFouten) {
    platformApiFouten.forEach((element): void => {
      if (element.field) {
        formikErrors = setIn(formikErrors, element.field, element.error);
      }
    });
  }

  return formikErrors;
}

export function verwijderApiFoutVoor(
  formik: {
    values: {};
    setFieldValue: (field: string, value: any) => void;
  },
  name: string
): void {
  if (name === undefined) {
    throw new Error("de 'name' parameter is verplicht");
  }
  const currentPlaformApiFouten: UiError[] | null = getIn(formik.values, "platformApiFouten");

  if (Array.isArray(currentPlaformApiFouten)) {
    const newPlaformApiFouten = currentPlaformApiFouten.filter((e): boolean => e.field !== name);
    if (currentPlaformApiFouten.length !== newPlaformApiFouten.length) {
      formik.setFieldValue("platformApiFouten", newPlaformApiFouten);
    }
  }
}

export function hasValue<T>(value: T | null | undefined): value is T {
  return typeof value !== "undefined" && value !== null;
}
class AssertValueError extends Error { }
export function assertValue<T>(value: T | null | undefined): asserts value is T {
  if (!hasValue(value)) {
    throw new AssertValueError("value must not be null or undefined");
  }
}

export function toArray<T>(value: T[] | T | null | undefined): T[] {
  return value instanceof Array ? value : hasValue(value) ? [value] : [];
}

export function calculatePercentageOfValue(percentage: number, value: number): number {
  return (value / 100) * percentage;
}

export function numberToString(value: number): string {
  const ones = ["een", "twee", "drie", "vier", "vijf", "zes", "zeven", "acht", "negen", "tien", "elf", "twaalf", "dertien", "veertien"];
  const tens = ["tien", "twintig", "dertig", "veertig", "vijftig", "zestig", "zeventig", "tachtig", "negentig"];

  if (value === 0) return "nul";
  if (value < 0) return `min ${numberToString(-value)}`;
  if (value === 100) return "honderd";
  if (value > 100) throw RangeError();

  if (value < 15) return ones[value - 1];
  if (value < 20) return `${ones[value - 10 - 1]}tien`;

  const tensPart = Math.floor(value / 10);
  const onesPart = value % 10;

  if (onesPart === 0) return tens[tensPart - 1];
  return `${ones[onesPart - 1]}en${tens[tensPart - 1]}`;
}
import { ItemType, MenuConfigType } from "adviesbox-shared";
import {
  FinancieringOptions,
  Financieringsoort,
  KlantprofielOnderwerpOptions,
  MenuInfo,
  MenuInfoKlantprofielOnderwerp,
  MenuInfoMotiveringOnderwerp,
  MenuInfoVoorstel,
  MotiveringOnderwerpsoort
} from "../.generated/forms/formstypes";

import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { getKlantProfielMenuIcon, getMotiveringMenuIcon, mapVoorstelMenu } from "./navigation-helper";

export const menuConfig: (
  menuInfo: MenuInfo,
  params: RouteParams,
  featureNewDashboard: boolean,
  featureRapport2: boolean
) => MenuConfigType[] = (menuInfo, params, featureNewDashboard, featureRapport2) => [
  {
    name: "advies",
    lists: [
      {
        name: "inventarisatie",
        title: "Inventarisatie",
        icon: "inventarisatie",
        items: [
          {
            name: "personalia",
            title: "Personalia",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/personalia`
          },
          {
            name: "inkomen-en-fiscus",
            title: "Inkomen & Fiscus",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/inkomen-en-fiscus`
          },
          {
            name: "pensioen",
            title: "Pensioen",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/pensioen`
          },
          {
            name: "woonsituatie",
            title: "Woonsituatie",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/woonsituatie`
          },
          {
            name: "huidige-situatie",
            title: "Huidige situatie",
            items: [
              {
                name: "hypotheek",
                title: "Hypotheek",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/huidige/hypotheek`,
                sidebar: true
              },
              {
                name: "kapitaalverzekering",
                title: "Kapitaalverzekering",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/huidige/kapitaalverzekering`
              },
              {
                name: "orv",
                title: "ORV",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/huidige/orv`
              },
              {
                name: "aov",
                title: "AOV",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/huidige/aov`
              },
              {
                name: "vermogen",
                title: "Vermogen",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/huidige/vermogen`
              },
              {
                name: "krediet",
                title: "Krediet",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/huidige/krediet`
              },
              {
                name: "uitkerende-lijfrente",
                title: "Uitkerende lijfrente",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/huidige/uitkerende-lijfrente`
              },
              {
                name: "Overig",
                title: "Overig",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/huidige/overige-posten`
              }
            ]
          }
        ]
      },
      {
        name: "analyse",
        title: "Analyse",
        icon: "analyse",
        items: [
          {
            name: "aanleiding",
            title: "Aanleiding",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/aanleiding`
          },
          {
            name: "woning",
            title: "Woning",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/woning`,
            isAllowed: () => menuInfo && menuInfo.financiering !== FinancieringOptions.GeenFinanciering
          }
        ]
      },
      {
        name: "klantprofiel",
        title: "Klantprofiel",
        icon: "klantprofiel",
        items: [
          {
            name: "algemeen",
            title: "Algemeen",
            items: [
              {
                name: "doelstelling",
                title: "Doelstelling",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/doelstelling`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.AlgemeenDoelstelling),
                iconSize: "s"
              },
              {
                name: "toekomstperspectief",
                title: "Toekomstperspectief",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/toekomstperspectief`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.AlgemeenToekomstperspectief),
                iconSize: "s"
              },
              {
                name: "risico-en-kennis",
                title: "Risico en kennis",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/risico-en-kennis`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.AlgemeenRisicoEnKennis),
                iconSize: "s"
              }
            ]
          },
          {
            name: "hypotheek",
            title: "Hypotheek",
            items: [
              {
                name: "hypotheek-algemeen",
                title: "Hypotheek algemeen",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/hypotheek-algemeen`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.HypotheekAlgemeen),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) => i.onderwerp === KlantprofielOnderwerpOptions.HypotheekAlgemeen
                  )
              },
              {
                name: "verduurzaming",
                title: "Verduurzaming",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/verduurzaming`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.HypotheekVerduurzaming),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.HypotheekVerduurzaming
                  )
              },
              {
                name: "verantwoorde-woonlasten",
                title: "Verantwoorde woonlasten",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/verantwoorde-woonlasten`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.HypotheekVerantwoordeWoonlasten),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.HypotheekVerantwoordeWoonlasten
                  )
              },
              {
                name: "eigen-geld",
                title: "Eigen geld",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/eigen-geld`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.HypotheekEigenGeld),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.HypotheekEigenGeld
                  )
              },
              {
                name: "dubbele-woonlasten",
                title: "Dubbele woonlasten",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/dubbele-woonlasten`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.HypotheekDubbeleWoonlasten),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.HypotheekDubbeleWoonlasten
                  )
              },
              {
                name: "fiscaal",
                title: "Fiscale aftrek",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/fiscaal`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.HypotheekFiscaal),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) => i.onderwerp === KlantprofielOnderwerpOptions.HypotheekFiscaal
                  )
              },
              {
                name: "hypotheekvorm",
                title: "Aflossingsvorm",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/hypotheekvorm`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.HypotheekHypotheekvorm),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.HypotheekHypotheekvorm
                  )
              },
              {
                name: "rentevastperiode",
                title: "Rentevastperiode",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/rentevastperiode`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.HypotheekRentevastperiode),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.HypotheekRentevastperiode
                  )
              },
              {
                name: "geldverstrekker",
                title: "Geldverstrekker",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/geldverstrekker`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.HypotheekGeldverstrekker),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.HypotheekGeldverstrekker
                  )
              }
            ]
          },
          {
            name: "risicos",
            title: "Risico's",
            items: [
              {
                name: "overlijden",
                title: "Overlijden",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/overlijden`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.RisicosOverlijden),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) => i.onderwerp === KlantprofielOnderwerpOptions.RisicosOverlijden
                  )
              },
              {
                name: "arbeidsongeschiktheid",
                title: "Arbeidsongeschiktheid",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/arbeidsongeschiktheid`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.RisicosArbeidsongeschiktheid),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.RisicosArbeidsongeschiktheid
                  )
              },
              {
                name: "werkloosheid",
                title: "Werkloosheid",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/werkloosheid`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.RisicosWerkloosheid),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.RisicosWerkloosheid
                  )
              },
              {
                name: "pensioen",
                title: "Pensioen",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/pensioen`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.RisicosPensioen),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) => i.onderwerp === KlantprofielOnderwerpOptions.RisicosPensioen
                  )
              }
            ]
          },
          {
            name: "vermogensopbouw",
            title: "Vermogensopbouw",
            items: [
              {
                name: "opbouw-doelvermogen",
                title: "Opbouw doelvermogen",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/opbouw-doelvermogen`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.VermogensopbouwOpbouwDoelvermogen),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.VermogensopbouwOpbouwDoelvermogen
                  )
              },
              {
                name: "risicoprofiel",
                title: "Risicoprofiel",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/klantprofiel/risicoprofiel`,
                icon: getKlantProfielMenuIcon(menuInfo, KlantprofielOnderwerpOptions.VermogensopbouwRisicoprofiel),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.klantprofiel?.onderwerpen?.find(
                    (i: MenuInfoKlantprofielOnderwerp) =>
                      i.onderwerp === KlantprofielOnderwerpOptions.VermogensopbouwRisicoprofiel
                  )
              }
            ]
          }
        ]
      },
      ...mapVoorstelMenu(
        menuInfo.voorstellen,
        (voorstel: MenuInfoVoorstel): ItemType => ({
          name: `voorstel-${voorstel.volgnummer}`,
          title: voorstel.naam || `Voorstel #${voorstel.volgnummer}`,
          icon: "voorstel",
          editable: true,
          uniqueId: voorstel.id,
          items: [
            {
              name: "uitgangspunten",
              title: "Uitgangspunten",
              link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/uitgangspunten`
            },
            {
              name: "financieringsbehoefte",
              title: "Financieringsbehoefte",
              link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/financieringsbehoefte`,
              isAllowed: () => menuInfo.financiering !== FinancieringOptions.GeenFinanciering
            },
            {
              name: "maximale-hypotheek",
              title: "Maximale hypotheek",
              link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/maximale-hypotheek`,
              isAllowed: () => menuInfo.financiering !== FinancieringOptions.GeenFinanciering
            },
            {
              name: "hypotheek-oversluiten",
              title: "Hypotheek oversluiten",
              link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/hypotheek-oversluiten`,
              isAllowed: () =>
                menuInfo.financiering === FinancieringOptions.HuidigeHypotheekWijzigen &&
                voorstel.soortFinanciering === Financieringsoort.Oversluiten
            },
            {
              name: "producten",
              title: "Producten",
              items: [
                {
                  name: "hypotheek",
                  title: "Hypotheek",
                  link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/producten/hypotheek`,
                  isAllowed: () =>
                    menuInfo.financiering !== FinancieringOptions.GeenFinanciering || voorstel.doorlopendeLeningdelen
                },
                {
                  name: "kapitaalverzekering",
                  title: "Kapitaalverzekering",
                  link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/producten/kapitaalverzekering`
                },
                {
                  name: "orv",
                  title: "ORV",
                  link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/producten/orv`
                },
                {
                  name: "aov",
                  title: "AOV",
                  link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/producten/aov`
                },
                {
                  name: "vermogen",
                  title: "Vermogen",
                  link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/producten/vermogen`
                },
                {
                  name: "krediet",
                  title: "Krediet",
                  link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/producten/krediet`
                },
                {
                  name: "uitkerende-lijfrente",
                  title: "Uitkerende lijfrente",
                  link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/producten/uitkerende-lijfrente`
                },
                {
                  name: "overige-posten",
                  title: "Overig",
                  link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/producten/overige-posten`
                }
              ]
            },
            {
              name: "acceptatie",
              title: "Acceptatie",
              link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/voorstel/${voorstel.id}/acceptatie`,
              isAllowed: () =>
                !featureNewDashboard && menuInfo && menuInfo.financiering !== FinancieringOptions.GeenFinanciering
            }
          ]
        })
      ),
      {
        name: "motivering",
        title: "Motivering",
        icon: "motivering",
        items: [
          {
            name: "algemeen",
            title: "Algemeen",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/algemeen`,
            iconSize: "s",
            icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.Algemeen)
          },
          {
            name: "hypotheek",
            title: "Hypotheek",
            items: [
              {
                name: "verduurzaming",
                title: "Verduurzaming",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/verduurzaming`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.Energie),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.Energie
                  )
              },
              {
                name: "verantwoorde-woonlasten",
                title: "Verantwoorde woonlasten",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/verantwoorde-woonlasten`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.VerantwoordeWoonlasten),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.VerantwoordeWoonlasten
                  )
              },
              {
                name: "eigengeld",
                title: "Eigen geld",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/eigen-geld`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.InbrengEigenGeld),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.InbrengEigenGeld
                  )
              },
              {
                name: "dubbelewoonlasten",
                title: "Dubbele woonlasten",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/dubbele-woonlasten`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.DubbeleWoonlasten),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.DubbeleWoonlasten
                  )
              },
              {
                name: "fiscaal",
                title: "Fiscale aftrek",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/fiscaal`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.FiscaleAftrek),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.FiscaleAftrek
                  )
              },
              {
                name: "hypotheekvorm",
                title: "Aflossingsvorm",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/hypotheekvorm`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.HypotheekAflossen),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.HypotheekAflossen
                  )
              },
              {
                name: "rentevastperiode",
                title: "Rentevastperiode",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/rentevastperiode`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.Rentevastperiode),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.Rentevastperiode
                  )
              },
              {
                name: "geldverstrekker",
                title: "Geldverstrekker",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/geldverstrekker`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.Geldverstrekker),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.Geldverstrekker
                  )
              }
            ]
          },
          {
            name: "risicos",
            title: "Risico's",
            items: [
              {
                name: "overlijden",
                title: "Overlijden",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/overlijden`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico),
                iconSize: "s",
                isAllowed: () => {
                  return menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) =>
                      i.onderwerp === MotiveringOnderwerpsoort.AfdekkenOverlijdensrisico
                  );
                }
              },
              {
                name: "arbeidsongeschiktheid",
                title: "Arbeidsongeschiktheid",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/arbeidsongeschiktheid`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.AfdekkenAOWW),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.AfdekkenAOWW
                  )
              },
              {
                name: "werkloosheid",
                title: "Werkloosheid",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/werkloosheid`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.AfdekkenWW),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.AfdekkenWW
                  )
              },
              {
                name: "pensioen",
                title: "Pensioen",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/pensioen`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.Pensioenvoorziening),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.Pensioenvoorziening
                  )
              }
            ]
          },
          {
            name: "vermogensopbouw",
            title: "Vermogensopbouw",
            items: [
              {
                name: "opbouw-doelvermogen",
                title: "Opbouw doelvermogen",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/opbouw-doelvermogen`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.WijzeOpbouwDoelvermogen),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) =>
                      i.onderwerp === MotiveringOnderwerpsoort.HoogteOpbouwDoelvermogen
                  )
              },
              {
                name: "risicoprofiel",
                title: "Risicoprofiel",
                link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/motivering/risicoprofiel`,
                icon: getMotiveringMenuIcon(menuInfo, MotiveringOnderwerpsoort.Risicoprofiel),
                iconSize: "s",
                isAllowed: () =>
                  menuInfo?.motivering?.onderwerpen?.find(
                    (i: MenuInfoMotiveringOnderwerp) => i.onderwerp === MotiveringOnderwerpsoort.Risicoprofiel
                  )
              }
            ]
          }
        ]
      },
      {
        name: "rapportage",
        title: "Rapportage",
        icon: "menulogboek", // TODO: eigen icoon (Zie 117317)
        items: menuInfo.structuurKeuzes?.map(x => ({
          name: x.structuurId,
          title: x.naam,
          link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/structuur/${x.structuurId}/rapportage`
        })),
        isAllowed: () => featureRapport2
      },
      {
        name: "aanvraag",
        title: "Aanvraag",
        icon: "aanvraag",
        items: [
          {
            name: "lening",
            title: "Lening",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/aanvraag-lening`,
            isAllowed: () => menuInfo && menuInfo.financiering !== FinancieringOptions.GeenFinanciering
          },
          {
            name: "taxatie",
            title: "Taxatie",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/taxatie`,
            isAllowed: () => menuInfo && menuInfo.financiering !== FinancieringOptions.GeenFinanciering
          },
          {
            name: "bankgarantie",
            title: "Bankgarantie",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/bankgarantie`,
            isAllowed: () => menuInfo && menuInfo.financiering !== FinancieringOptions.GeenFinanciering
          },
          {
            name: "aanvraag",
            title: "Aanvraag samenstellen",
            link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/aanvraag`
          }
        ]
      }
    ]
  }
  // Logboek momenteel nog niet bruikbaar
  // {
  //   name: "logboek",
  //   title: "Logboek",
  //   lists: [
  //     {
  //       name: "logboek",
  //       title: "Logboek",
  //       icon: "menulogboek",
  //       link: `/vestiging/${params.vestiging}/adviesdossier/${params.adviesdossier}/logboek`
  //     }
  //   ]
  // }
];

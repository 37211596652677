import { ErrorPage, PageLoading, SettingsType, withErrorBoundary } from "adviesbox-shared";
import React, { ReactElement } from "react";

import AanvraagLening from "./aanvraag-lening";
import { InvalidAdviesboxResultErrorPage } from "../shared/components/fetch-error-page/fetch-error-page";
import { mapAanvraagLeningDlToUi } from "./infra/map-aanvraag-lening-dl-to-ui";
import { mapAanvraagLeningUiToDl } from "./infra/map-aanvraag-lening-ui-to-dl";
import { mapDlTargetToAanvraagLeningUiField } from "./infra/map-aanvraag-lening-dl-target-to-ui-field";
import { useAdviesboxData } from "../shared/hooks/use-adviesbox-data";
import { useHdnData } from "../shared/hooks/use-hdn-data";

type RouteParams = {
  vestiging: string;
  adviesdossier: string;
};

const createUrl = (s: SettingsType, p: RouteParams): string =>
  `${s.klantdossiersFormsOrigin}/Adviesdossiers/${p.adviesdossier}/Lening`;

const AanvraagLeningAjax = (): ReactElement => {
  const { loading, error, data, platformData, saveData } = useAdviesboxData(
    createUrl,
    p => p.adviesdossier,
    mapAanvraagLeningDlToUi,
    mapAanvraagLeningUiToDl,
    mapDlTargetToAanvraagLeningUiField
  );

  const { hdnKeuzelijst } = useHdnData("AX", "MaatwerkOplCodeMijType", data?.lening.geselecteerdVoorstel.hdnOntvangerCode);

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <ErrorPage error={error} data={platformData} />;
  }

  if (!data) {
    return <InvalidAdviesboxResultErrorPage />;
  }

  return <AanvraagLening saveData={saveData} {...data} maatwerkLijst={hdnKeuzelijst} />;
};

AanvraagLeningAjax.displayName = "AanvraagLeningAjax";

export default withErrorBoundary(AanvraagLeningAjax);

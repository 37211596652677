import { RapportageState, rapportageSchema } from "./rapportage-schema";
import { RapportSamenstellenStructuurOutput, SoortElementOptions } from "../../.generated/forms/formstypes";
import { UiName } from "adviesbox-shared/utils/types";
import { flattenElements } from "./rapportage-helper";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";

export const mapRapportageToUi = createMapToUi(rapportageSchema)
  .with<{ structuurId: string }>()
  .from<RapportSamenstellenStructuurOutput>({
    structuurId: (_, { structuurId }) => structuurId,
    naam: v => v.structuur?.naam,
    volgnummer: v => v.structuur?.volgnummer,
    elementen: v => flattenElements(v.structuur?.elementen ?? /* istanbul ignore next */ [], null, []),
    filteredElementen: v =>
      flattenElements(v.structuur?.elementen ?? /* istanbul ignore next */ [], null, [])?.filter(
        e => e.soortElement === SoortElementOptions.Hoofdstuk
      ),
    selectedElement: _ => null
  });

export const mapRapportageDlToUi = (
  structuurId: string,
  data: RapportSamenstellenStructuurOutput
): RapportageState | null => {
  if (!data?.structuur) {
    return null;
  }
  return mapRapportageToUi({ structuurId })(data);
};
export function mapDlTargetToRapportageUiField(target: string): UiName | null {
  return null;
}

import React, { ReactElement, ReactNode, useState, useEffect, useContext } from "react";
import DossierAanvraagFoutenContext, { DossierFoutenContextType } from "./dossier-aanvraag-fouten-context";
import {
  AanvullendeGegevensType,
  aanvullendeGegevensSchema
} from "../components/dossier/dossierbalk-fouten-dropdown/aanvullende-gegevens-schema";
import { NotificatiesContext, useFeature, Notificaties2Context } from "adviesbox-shared";
import { mapAllErrorsData } from "../../aanvraag/infra/aanvraag-helper";
import { ProductTypeName } from "../../aanvraag/infra/aanvraag-schema";

export type nieuweAanvraagType = {
  isloading: boolean;
  receivedId: string | null;
  productType: ProductTypeName | null;
  partijCode: string | null;
  closeModal: boolean | null;
};

const setContext = (
  setAanvullendeGegevens: (aanvullendeGegevens: AanvullendeGegevensType) => void,
  aanvullendeGegevens: AanvullendeGegevensType,
  setNieuweAanvraag: (nieuweAanvraag: nieuweAanvraagType | null) => void,
  nieuweAanvraag: nieuweAanvraagType | null
): DossierFoutenContextType => ({
  setAanvullendeGegevens,
  aanvullendeGegevens,
  setNieuweAanvraag,
  nieuweAanvraag
});

const DossierAanvraagFoutenProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const featureNotificaties2 = useFeature("FeatureNotificaties2");
  const { subscribeNotificationEvent, setSeen } = useContext(Notificaties2Context);
  const [aanvullendeGegevens, setAanvullendeGegevens] = useState<AanvullendeGegevensType>(
    aanvullendeGegevensSchema.default()
  );
  const [nieuweAanvraag, setNieuweAanvraag] = useState<nieuweAanvraagType | null>(null);
  const { notificaties } = useContext(NotificatiesContext);

  /* istanbul ignore next */
  useEffect(
    () => {
      if (featureNotificaties2) {
        subscribeNotificationEvent("nieuwe-aanvraag-result", 400, async data => {
          const mappedAanvullendeGegevens = mapAllErrorsData(data.body, nieuweAanvraag);
          setAanvullendeGegevens(mappedAanvullendeGegevens);
          if ((mappedAanvullendeGegevens as any).modalFoutenArray.find(v => v.locatie && v.app && v.relativeUrl)) {
            // open dropdown
          } else {
            // open modal
          }
        });
        subscribeNotificationEvent("nieuwe-aanvraag-result", 200, async (data: any) => {
          const mappedAanvullendeGegevens = mapAllErrorsData(data.body, nieuweAanvraag);
          setAanvullendeGegevens(mappedAanvullendeGegevens);
          // openen in target met url, zodat een reeds openstaande pagina gewoon ververst wordt en niet een extra tab oplevert
          window.open(data.url, data.url);
        });
        subscribeNotificationEvent("nieuwe-hypotheekvergelijker-result", 200, async (data: any) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          setSeen(data, true);
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Als er een notificatie wordt ontvangen voor de aanvraag, dient het scherm uit wachtstand gehaald te worden.
  useEffect(() => {
    if (
      !featureNotificaties2 &&
      nieuweAanvraag?.receivedId &&
      notificaties?.some(entry => entry.uniekID === nieuweAanvraag?.receivedId)
    ) {
      const notificatie = notificaties.find(entry => entry.uniekID === nieuweAanvraag?.receivedId);
      if (notificatie?.message) {
        const message = JSON.parse(notificatie.message);
        const mappedAanvullendeGegevens = mapAllErrorsData(message, nieuweAanvraag);
        setAanvullendeGegevens(mappedAanvullendeGegevens);

        /* istanbul ignore next */
        setNieuweAanvraag({
          isloading: false,
          closeModal: nieuweAanvraag?.closeModal === null ? null : true,
          partijCode: nieuweAanvraag?.partijCode ?? null,
          productType: nieuweAanvraag?.productType ?? null,
          receivedId: null
        });
      }
    }
  }, [notificaties, nieuweAanvraag, featureNotificaties2]);

  return (
    <DossierAanvraagFoutenContext.Provider
      value={setContext(setAanvullendeGegevens, aanvullendeGegevens, setNieuweAanvraag, nieuweAanvraag)}
    >
      {children}
    </DossierAanvraagFoutenContext.Provider>
  );
};

export default DossierAanvraagFoutenProvider;

import React, { ReactElement, useContext, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { Form, FormikContextType } from "formik";

import Onderpand from "./onderpand/onderpand";

import Financiering from "./financiering/financiering";
import Erfpacht from "./erfpacht/erfpacht";
import Vermogen from "./vermogen/vermogen";

import FinancieringsopzetBestaandebouw from "./financieringsopzet-bestaandebouw/financieringsopzet-bestaandebouw";
import FinancieringsopzetNieuwbouw from "./financieringsopzet-nieuwbouw/financieringsopzet-nieuwbouw";
import FinancieringsopzetOversluiten from "./financieringsopzet-oversluiten/financieringsopzet-oversluiten";
import { Financieringsoort } from "../.generated/forms/formstypes";
import { RouteParams } from "../shared/paramrouting/paramrouting-context";
import { SaveButton } from "../shared/components/save-button/save-button";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { useParams } from "react-router";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { financieringsBehoefteAsyncSideEffects } from "./infra/determine-financieringsbehoefte-async-side-effects";
import { PageLoading, Card, CardWrapper, FormFirstFocus, LabeledResult, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import { WithSaveData } from "../shared/utils/save-data";
import {
  FinancieringsbehoefteState,
  FinancieringsbehoefteProps,
  FinancieringsbehoefteContextType
} from "./infra/financieringsbehoefte-types";
import { determineFinancieringsbehoefteSideEffects } from "./infra/determine-financieringsbehoefte-side-effects";
import { financieringsbehoefteSchema } from "./infra/financieringsbehoefte-schema";
import { AppDataContext } from "../navigation/appdata-context";
import { getFinancieringsbehoefteTextResources } from "./infra/financieringsbehoefte-resources";
import { NhgWijzigenModal } from "./vermogen/nhg-wijzigen-modal/nhg-wijzigen-modal";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const Financieringsbehoefte = (
  props: FormikContextType<FinancieringsbehoefteState> & FinancieringsbehoefteProps
): ReactElement => {
  const { values, isSubmitting } = props;
  const volgnummerVoorstel1 = 1;
  const overigeVoorstellen = values.volgnummerVoorstel > volgnummerVoorstel1;
  const {seRunning, setSErunning } = useContext(AppDataContext);
  const modelStateModal = useState<boolean | null>(null);
  const [showModal, setShowModal] = modelStateModal;

  const titleHypotheekWijzigen = (param: Financieringsoort): string => {
    switch (param) {
      case Financieringsoort.Oversluiten:
        return "Financieringsopzet oversluiten";
      case Financieringsoort.Heropname:
        return "Financieringsopzet onderhandse verhoging";
      case Financieringsoort._1eHypotheek:
        return "Financieringsopzet 1e hypotheek";
      case Financieringsoort._2eHypotheek:
        return "Financieringsopzet 2e hypotheek";
      case Financieringsoort._3eHypotheek:
        return "Financieringsopzet 3e hypotheek";
      case Financieringsoort._4eHypotheek:
        return "Financieringsopzet 4e hypotheek";
      default:
        return "Financieringsopzet omzetting leningdeel";
    }
  };

  const renderFinancieringsopzet = (financieringsSoort: Financieringsoort, heeftDoorlopendeLeningdelen: boolean | null): ReactElement => {  
    const renderCard = (title: string, component: ReactElement): ReactElement => (  
      <Card title={title}>  
        {component}
        {financieringsSoort !== Financieringsoort.Omzetting && heeftDoorlopendeLeningdelen &&
        <span className="m-3 input-rood card-adviesbox-text">
          {getFinancieringsbehoefteTextResources("WarningBevoorSchottingsPercentage")}
        </span>}
        {financieringsSoort === Financieringsoort.Omzetting && !heeftDoorlopendeLeningdelen &&
        <span className="m-3 input-rood card-adviesbox-text">
          {getFinancieringsbehoefteTextResources("WarningOmzettingLeningdeel")}
        </span>}
      </Card>  
    );  
    
    switch (financieringsSoort) {  
      case Financieringsoort.AankoopBestaandeBouw:  
        return renderCard("Financieringsopzet bestaande bouw", <FinancieringsopzetBestaandebouw setShowModal={setShowModal} showModal={showModal} loading={seRunning}/>);  
      case Financieringsoort.AankoopNieuwbouw:  
        return renderCard("Financieringsopzet nieuwbouw", <FinancieringsopzetNieuwbouw setShowModal={setShowModal} showModal={showModal} loading={seRunning}/>);  
      default:  
        return renderCard(titleHypotheekWijzigen(financieringsSoort), <FinancieringsopzetOversluiten setShowModal={setShowModal} showModal={showModal} loading={seRunning}/>);  
    }  
  };

  const paramContext = useParams<RouteParams>();
  const voorstelId = paramContext.voorstel || "";

  return (
    <>
      <FormFirstFocus>
        <Toast show={!!values.error} transition={undefined} autohide>
          <Toast.Body>{values.error}</Toast.Body>
        </Toast>

        <Form>
          {isSubmitting && <PageLoading />}
          <ISWSideEffects<FinancieringsbehoefteState>
            sync={determineFinancieringsbehoefteSideEffects({ modelStateModal })}
            async={financieringsBehoefteAsyncSideEffects({ voorstelId })}
            asyncStartStopCallback={setSErunning}
          />

          <CardWrapper className="pl-3">
            <div className="text-container">
              <div className="save-btn-position">
                <div className="button-container">
                  <SaveButton context={props} />
                </div>
              </div>
            </div>
          </CardWrapper>

          <PlatformFoutenSamenvatting />

          <div className="d-flex flex-wrap flex-row">
            <CardWrapper flexType="flex-column" className="pl-3" maxRowCount={0}>
              <Card title="Financiering">
                {overigeVoorstellen && (
                  <LabeledResult
                    readonly={true}
                    caption={"Overgenomen van het voorstel"}
                    result={() => values.omschrijvingVoorstel1}
                  />
                )}
                <Financiering readonly={overigeVoorstellen} />
              </Card>
              <Card
                title="Onderpand"
                subtitle={overigeVoorstellen ? `Overgenomen van het voorstel ${values.omschrijvingVoorstel1}` : ""}
              >
                <Onderpand readonly={overigeVoorstellen} />
              </Card>
              {(values.financiering.soortFinanciering === Financieringsoort.AankoopNieuwbouw ||
                values.financiering.soortFinanciering === Financieringsoort.AankoopBestaandeBouw ||
                values.erfpacht.erfpacht) && (
                <Card
                  title="Erfpacht"
                  subtitle={overigeVoorstellen ? `Overgenomen van het voorstel ${values.omschrijvingVoorstel1}` : ""}
                >
                  <Erfpacht readonly={overigeVoorstellen} />
                </Card>
              )}
              <Card
                title={values.vermogen.overwaardeBerekeningTonen ? "Overwaarde huidige woning" : "Vermogen"}
                subtitle={overigeVoorstellen ? `Overgenomen van het voorstel ${values.omschrijvingVoorstel1}` : ""}
              >
                <Vermogen readonly />
              </Card>
            </CardWrapper>
            <CardWrapper flexType="flex-column" className="pl-xl-0 pl-lg-3 pl-md-3 pl-sm-3" maxRowCount={3}>
            {showModal && values.financiering.analyseHeeftLeningdelen && <NhgWijzigenModal
              onCancel={() => {
                setShowModal(false);
              }}
              data={values}></NhgWijzigenModal>}
            {renderFinancieringsopzet(values.financiering.soortFinanciering, values.financiering.heeftDoorlopendeLeningdelen)}
            </CardWrapper>
          </div>

          <DevDebug />
        </Form>
      </FormFirstFocus>
    </>
  );
};

Financieringsbehoefte.displayName = "Financieringsbehoefte";

export default withAdviesboxFormik<
  FinancieringsbehoefteProps & WithSaveData<FinancieringsbehoefteState>,
  FinancieringsbehoefteState
>({
  // Transform outer props into form values
  mapPropsToValues: (e: FinancieringsbehoefteProps): FinancieringsbehoefteState => {
    const { saveData, ...state } = e;
    return state;
  },
  validationSchema: financieringsbehoefteSchema,
  createValidationContext: (_, values): FinancieringsbehoefteContextType => {
    return { leningdelenWordenAfgelost: values.financieringsopzet.leningdelenWordenAfgelost };
  }
})(withAITracking(insightsReactPlugin, Financieringsbehoefte));

import { SettingsType } from "adviesbox-shared";
import { FormikContextType } from "formik";
import { User } from "oidc-client";
import { NewOutput } from "../.generated/forms/formstypes";
import { hasJSONResponse } from "../shared/utils/ajax";
import { InBeheerNemenType } from "./infra/inbeheernemen-types";

export const inbeheernemenModalApi = async (
  settings: SettingsType,
  user: User | null,
  vestigingId: string,
  voorstelId: string,
  setFieldValue: FormikContextType<InBeheerNemenType>["setFieldValue"]
): Promise<NewOutput> => {
  const url = `${settings.klantdossiersFormsOrigin}/Voorstellen/${voorstelId}/InBeheerNemen`;
  const rsp = await fetch(url, {
    method: "POST",
    headers: {
      authorization: `${user?.token_type} ${user?.access_token}`,
      "Ocp-Apim-Subscription-Key": settings.OcpApimSubscriptionKey,
      "Content-Type": "application/json;charset=UTF-8",
      vestigingId,
      voorstelId: voorstelId
    },
    body: null
  });

  if (!(rsp.ok && hasJSONResponse(rsp))) {
    setFieldValue("platformApiFouten", [
      {
        field: "",
        label: `Fout: ${rsp.status}`,
        error: "Fout bij aanroep van web-api"
      }
    ]);
    throw Error(`Fout bij aanroep van web-api '${url}'. Resultaat bevat geen JSON.`);
  }

  const json = await rsp.json();

  if (!json.isValid) {
    setFieldValue("platformApiFouten", [
      {
        field: "",
        label: `Fout: ${rsp.status}`,
        error: json.message
      }
    ]);
    throw Error(`Fout bij aanroep van web-api '${url}'. Input is niet valid.`);
  }
  return json;
};

import { FlattendElementType } from "./rapportage-schema";
import { RapportSamenstellenElement, SoortElementOptions } from "../../.generated/forms/formstypes";

export const flattenElements = (
  elements: RapportSamenstellenElement[],
  parent: RapportSamenstellenElement | null,
  parentHoofdstukken: number[]
): FlattendElementType[] => {
  let flattened: FlattendElementType[] = [];

  elements.forEach(element => {
    if (parent?.soortElement === SoortElementOptions.Hoofdstuk && !parentHoofdstukken.some(x => x === parent?.code)) {
      parentHoofdstukken.push(parent?.code ?? 0);
    }
    const flattendElement: FlattendElementType = {
      code: element.code,
      naam: element.naam ?? "",
      soortElement: element.soortElement,
      volgnummer: element.volgnummer,
      inhoud: element.inhoud,
      perAdviesAanpasbaar: element.inhoudWijzigbaar,
      ParentHoodstukken: parentHoofdstukken,
      ParentCode: parent?.code ?? null,
      parentNaam: parent?.naam ?? null
    };
    // Push the current element to the flattened array
    flattened.push(flattendElement);

    // If the element has sub-elements, recursively flatten them
    if (element?.elementen && (element.elementen.length ?? 0) > 0) {
      flattened = flattened.concat(flattenElements(element.elementen, { ...element }, [...parentHoofdstukken]));
    }
  });
  return flattened;
};

export const unflattenElements = (flattenedElements: FlattendElementType[]): RapportSamenstellenElement[] => {
  const elementMap: { [key: string]: RapportSamenstellenElement } = {};
  const rootElements: RapportSamenstellenElement[] = [];

  flattenedElements.forEach(element => {
    const newElement: RapportSamenstellenElement = {
      code: element.code,
      naam: element.naam,
      soortElement: element.soortElement,
      volgnummer: element.volgnummer,
      inhoud: element.inhoud,
      inhoudWijzigbaar: element.perAdviesAanpasbaar ?? false,
      elementen: []
    };

    elementMap[element.code] = newElement;

    if (element.ParentCode === null) {
      rootElements.push(newElement);
    } else {
      const parentElement = elementMap[element.ParentCode];
      /* istanbul ignore else */
      if (parentElement) {
        parentElement.elementen = parentElement.elementen || [];
        parentElement.elementen.push(newElement);
      }
    }
  });

  return rootElements;
};
